<template>
  <urne />
</template>

<script>
import Urne from '../components/Urne.vue'

export default {
  name: 'UrneView',
  components: {
    Urne
  }
}
</script>
