import { createStore } from 'vuex'

export default createStore({
  state: {
    accessToken: '',
    blobDialog: {
      show: false,
      source: null
    },
    error: false,
    headers: {
      raadplegen: false,
      samenstellen: false
    },
    loading: [],
    loggedIn: false,
    loggedInId: null,
    loggedInType: null,
    loggingIn: false,
    sidebar: null,
    sidebarColor: '#FBF7EF, #FBF7EF',
    snackbars: []
  },
  mutations: {
    SET_ACCESS_TOKEN (state, token) {
      state.accessToken = token
    },
    SET_SIDEBAR (state, payload) {
      state.sidebar = payload
    },
    SHOW_LOADER (state, object) {
      const isCurrentlyLoading = state.loading.includes(object.component)

      if (object.loading && !isCurrentlyLoading) {
        state.loading.push(object.component)
      } else if (!object.loading && isCurrentlyLoading) {
        state.loading.splice(state.loading.indexOf(object.component), 1)
      }
    },
    SHOW_SNACKBAR (state, snackbar) {
      let show = true

      if (state.snackbars.length > 0) {
        const lastSnackbar = state.snackbars[state.snackbars.length - 1]

        if (lastSnackbar.show && lastSnackbar.color === snackbar.color && lastSnackbar.text === snackbar.text) {
          show = false
        }
      }

      if (show) {
        state.snackbars.push(snackbar)
      }
    }
  },
  actions: {
    showLoader ({ commit }, object) {
      commit('SHOW_LOADER', object)
    },
    showSnackbar ({ commit }, snackbar) {
      commit('SHOW_SNACKBAR', snackbar)
    }
  }
})
