<template>
  <facturatie />
</template>

<script>
import Facturatie from '../components/Facturatie.vue'

export default {
  name: 'FacturatieView',
  components: {
    Facturatie
  }
}
</script>
