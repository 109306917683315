<template>
  <locaties
    name="Aulas"
  />
</template>

<script>
import Locaties from '../components/Locaties.vue'

export default {
  name: 'AulasView',
  components: {
    Locaties
  }
}
</script>
