<template>
  <rouwdrukwerk />
</template>

<script>
import Rouwdrukwerk from '../components/Rouwdrukwerk.vue'

export default {
  name: 'RouwdrukwerkView',
  components: {
    Rouwdrukwerk
  }
}
</script>
