<template>
  <koffietafel />
</template>

<script>
import Koffietafel from '../components/Koffietafel.vue'

export default {
  name: 'KoffietafelView',
  components: {
    Koffietafel
  }
}
</script>
