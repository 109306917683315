<template>
  <enquete />
</template>

<script>
import Enquete from '../components/Enquete.vue'

export default {
  name: 'EnqueteView',
  components: {
    Enquete
  }
}
</script>
