import AccountView from '../views/AccountView.vue'
import AulasView from '../views/AulasView.vue'
import AdministratieView from '../views/AdministratieView.vue'
import BereidJeUitvaartVoorView from '../views/BereidJeUitvaartVoorView.vue'
import BloemenView from '../views/BloemenView.vue'
import CondoleancesView from '../views/CondoleancesView.vue'
import ContactView from '../views/ContactView.vue'
import COVID19View from '../views/COVID19View.vue'
import DraaiboekView from '../views/DraaiboekView.vue'
import EnqueteView from '../views/EnqueteView.vue'
import ExtraDienstenView from '../views/ExtraDienstenView.vue'
import ErrorDetailView from '../views/ErrorDetailView.vue'
import FacturatieView from '../views/FacturatieView.vue'
import FunerariaView from '../views/FunerariaView.vue'
import KoffietafelView from '../views/KoffietafelView.vue'
import GrafmonumentenView from '../views/GrafmonumentenView.vue'
import HerinneringsjuwelenView from '../views/HerinneringsjuwelenView.vue'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import LogoutView from '../views/LogoutView.vue'
import MiniUrneView from '../views/MiniUrneView.vue'
import MuziekView from '../views/MuziekView.vue'
import NieuwsView from '../views/NieuwsView.vue'
import OverlijdensberichtenView from '../views/OverlijdensberichtenView.vue'
import OverOnsView from '../views/OverOnsView.vue'
import PublicatiesView from '../views/PublicatiesView.vue'
import RouwdrukwerkView from '../views/RouwdrukwerkView.vue'
import TheContainer from '../components/TheContainer.vue'
import UitvaartView from '../views/UitvaartView.vue'
import UitvaartverzekeringView from '../views/UitvaartverzekeringView.vue'
import UrneView from '../views/UrneView.vue'
import VeelgesteldeVragenView from '../views/VeelgesteldeVragenView.vue'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    children: [
      {
        component: AccountView,
        name: 'Uw persoonlijke account',
        path: 'Account'
      },
      {
        component: AulasView,
        name: 'De aula\'s van Verstappen',
        path: 'Aulas'
      },
      {
        component: AdministratieView,
        name: 'Administratie',
        path: 'Administratie'
      },
      {
        component: BereidJeUitvaartVoorView,
        name: 'Bereid je uitvaart voor',
        path: 'BereidJeUitvaartVoor'
      },
      {
        component: BloemenView,
        name: 'Bloemen',
        path: 'Bloemen'
      },
      {
        component: CondoleancesView,
        name: 'Condoleances',
        path: 'Condoleances'
      },
      {
        component: ContactView,
        name: 'Contact',
        path: 'Contact'
      },
      {
        component: COVID19View,
        name: 'Maatregelen COVID-19',
        path: 'COVID19'
      },
      {
        component: DraaiboekView,
        name: 'Draaiboek uitvaart',
        path: 'Draaiboek'
      },
      {
        component: EnqueteView,
        name: 'Mijn mening',
        path: 'Enquete'
      },
      {
        component: ExtraDienstenView,
        name: 'Extra diensten',
        path: 'ExtraDiensten'
      },
      {
        component: ErrorDetailView,
        name: 'Error',
        path: 'Error'
      },
      {
        component: FacturatieView,
        name: 'Kostenoverzicht',
        path: 'Facturatie'
      },
      {
        component: FunerariaView,
        name: 'Funeraria',
        path: 'Funeraria'
      },
      {
        component: KoffietafelView,
        name: 'Aanwezigen koffietafel',
        path: 'Koffietafel'
      },
      {
        component: GrafmonumentenView,
        name: 'Grafmonumenten',
        path: 'Grafmonumenten'
      },
      {
        component: HerinneringsjuwelenView,
        name: 'Herinneringsjuwelen',
        path: 'Herinneringsjuwelen'
      },
      {
        path: '/',
        redirect: 'Home'
      },
      {
        component: HomeView,
        name: 'Home',
        path: 'Home'
      },
      {
        component: LoginView,
        name: 'Login',
        path: 'Login'
      },
      {
        component: LogoutView,
        name: 'Logout',
        path: 'Logout'
      },
      {
        component: MiniUrneView,
        name: 'MiniUrne',
        path: 'MiniUrne'
      },
      {
        component: MuziekView,
        name: 'Muziek uitvaart',
        path: 'Muziek'
      },
      {
        component: NieuwsView,
        name: 'Nieuws',
        path: 'Nieuws'
      },
      {
        component: OverOnsView,
        name: 'Meer over Verstappen',
        path: 'OverOns'
      },
      {
        component: OverlijdensberichtenView,
        name: 'Overlijdensberichten',
        path: 'Overlijdensberichten'
      },
      {
        component: PublicatiesView,
        name: 'Publicaties',
        path: 'Publicaties'
      },
      {
        component: RouwdrukwerkView,
        name: 'Rouwdrukwerk',
        path: 'Rouwdrukwerk'
      },
      {
        component: UitvaartView,
        name: 'Uitvaart',
        path: 'Uitvaart'
      },
      {
        component: UitvaartverzekeringView,
        name: 'Uitvaartverzekering',
        path: 'Uitvaartverzekering'
      },
      {
        component: UrneView,
        name: 'Urne',
        path: 'Urne'
      },
      {
        component: VeelgesteldeVragenView,
        name: 'Veelgestelde vragen',
        path: 'VeelgesteldeVragen'
      },
      {
        component: ErrorDetailView,
        name: 'Error 404',
        path: '*'
      }
    ],
    component: TheContainer,
    path: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router