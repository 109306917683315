export default {
  data () {
    return {
      constantMixin: {
        codes: {
          betalingsmethode: {
            name: 'betalingsmethode',
            values: {
              onlineBetaling: 1,
              achterafBetalen: 2
            }
          },
          blobType: {
            name: 'blob_type',
            values: {
              uitvaartMuziek: 1,
              uitvaartProjectie: 2,
              rouwdrukwerk: 3,
              facturatie: 4
            }
          },
          enqueteTypeVraag: {
            name: 'enquete_type_vraag',
            values: {
              radiobutton: 1,
              checkbox: 2,
              langeTekst: 3,
              korteTekst: 4
            }
          },
          facturatieEnDocBestandstype: {
            name: 'facturatie_en_doc_bestandstype',
            values: {
              factuur: 1,
              uittrekselVanOverlijden: 2,
              bewijsVanKortVerlet: 3,
              crematiepapieren: 4,
              overige: 5
            }
          },
          muziekType: {
            name: 'muziek_type',
            values: {
              instrumentaal: 1,
              vocaal: 2
            }
          },
          rouwdrukBestandstype: {
            name: 'rouwdruk_bestandstype',
            values: {
              rouwbrief: 1,
              overige: 2,
              rouwbriefOnline: 3
            }
          },
          typeFacturatie: {
            name: 'type_facturatie',
            values: {
              factuurRechtstreeks: 1,
              factuurAanDerden: 2,
              factuurDossier: 3,
              kasblad: 4,
              bancontact: 5,
              onlineBetaling: 6
            }
          },
          webshopType: {
            name: 'webshop_type',
            values: {
              bloemstukken: 1,
              juwelen: 2,
              miniUrne: 3,
              urne: 4
            }
          }
        },
        guids: {
          empty: '00000000-0000-0000-0000-000000000000'
        },
        productGroepen: {
          bloemstukken: {
            boeketten: {
              deadline: 'bloemenBoeket',
              id: '7197c51a-db37-4b12-a63e-bf7978170ca5',
              text: 'Boeketten'
            },
            echteBloemen: {
              deadline: 'bloemenEcht',
              id: '358a9f35-770b-484d-a593-16f90c0a31e9',
              text: 'Natuurbloemen'
            },
            kinderbloemen: {
              id: 'd9c0d9ce-aa25-4372-b669-a6457f749aa8'
            },
            urnekransen: {
              deadline: 'bloemenUrne',
              id: '6626c386-80f9-4070-8c45-ee936a59634d',
              text: 'Urnekransen'
            },
            veldbloemen: {
              deadline: 'bloemenVeld',
              id: '8f3c767a-0df0-4f5d-b6f0-20b5e3367b26',
              text: 'Veldbloemen'
            },
            zijdenBloemen: {
              deadline: 'bloemenZijde',
              text: 'Zijdebloemen',
              id: 'f4e0832c-f199-484d-8149-b75dad9d7298'
            }
          },
          juwelen: {
            armband: {
              text: 'Armband',
              id: '6773c488-fea8-4009-8bba-c6af18c925ad'
            },
            hangertje: {
              text: 'Hangertje',
              id: '20f6d727-156e-4412-b832-9007cac543c8'
            },
            ketting: {
              text: 'Ketting',
              id: '1edab93d-628a-4bbc-ac6c-f15a60e9a4c8'
            },
            ring: {
              text: 'Ring',
              id: 'c662328f-6f04-4419-916c-eb986a7fb610'
            }
          },
          miniUrne: {
            aluMetaal: {
              text: 'Alu/metaal',
              id: '50b10e71-520f-447f-b384-70b8e8eb955c'
            },
            askader: {
              text: 'Askader',
              id: '75886bb5-28b3-46ce-83fc-c4a2eff3adb3'
            },
            brons: {
              text: 'Brons',
              id: '3b463047-48ee-4cb9-9ca5-148251ed811c'
            },
            glas: {
              text: 'Glas',
              id: 'ff815d1c-25ee-4907-a4da-a4150356615f'
            },
            hout: {
              text: 'Hout',
              id: 'c42b3692-02f4-406a-8c66-43e2dd75f272'
            },
            keramiek: {
              text: 'Keramiek',
              id: 'c8db57c5-2387-466a-972a-1095c46b74eb'
            },
            messing: {
              text: 'Messing',
              id: '904e294c-3ab8-4dad-9d60-6037ce556877'
            }
          },
          urne: {
            aluminium: {
              text: 'Aluminium',
              id: '68292d4f-8dca-453e-b0b9-93c8fe42def7'
            },
            ecologisch: {
              text: 'Ecologisch',
              id: 'd95668ec-376c-4730-b716-c421ed642690'
            },
            hout: {
              text: 'Hout',
              id: '263da078-eb01-477e-b234-8a25adf1962e'
            },
            keramiek: {
              text: 'Keramiek',
              id: '01e434e4-4b8f-44bb-bb77-651d2862652c'
            },
            marmer: {
              text: 'Marmer',
              id: '46f34a24-0c86-40f4-8290-5497de0e30fc'
            },
            messing: {
              text: 'Messing',
              id: '10d72edd-81b6-4de2-a6f5-239e2c5b3aec'
            },
            metaal: {
              text: 'Metaal',
              id: 'e51a338d-b0cc-41c5-a6cf-555b1b98bcde'
            },
            polymeer: {
              text: 'Polymeer',
              id: 'f4d11762-ea0a-4860-b009-9d0304ef7b6c'
            }
          }
        },
        rapporten: {
          condoleances: {
            id: 'b0f71dd9-3e0f-4056-bb76-05f2b9d313a5'
          }
        }
      }
    }
  }
}
