<template>
  <v-app>
    <the-loader />
    <the-snackbar-stack />
    <the-header />
    <the-view />
    <the-footer />
    <the-blob-dialog />
  </v-app>
</template>

<script>
import TheBlobDialog from './TheBlobDialog.vue'
import TheFooter from './TheFooter.vue'
import TheLoader from './TheLoader.vue'
import TheSnackbarStack from './TheSnackbarStack.vue'
import TheHeader from './TheHeader.vue'
import TheView from './TheView.vue'

export default {
  name: 'DashboardIndex',
  components: {
    TheBlobDialog,
    TheFooter,
    TheLoader,
    TheSnackbarStack,
    TheHeader,
    TheView
  },
  data: () => ({
    expandOnHover: false
  })
}
</script>
