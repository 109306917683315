<template>
  <v-container
    :style="{'margin-bottom': MarginBottom}"
    color="white"
    fluid
  >
    <v-app-bar
      class="hidden-sm-and-down px-16"
      fixed
      :height="heights.appbar1"
      id="header"
    >
      <v-img
        class="logo"
        :src="require('@/assets/images/logo.png')"
        style="min-width: 300px;"
        @click="Home()"
      />
      <v-spacer />
      <v-row>
        <v-col
          class="clear headerContact"
          cols="12"
          lg="12"
        >
          <span
            class="float-right ml-8"
          >
            een overlijden? Bel ons: 014 58 84 65
          </span>
          <span
            class="float-right mr-8"
          >
            <v-icon
              class="mr-1"
              color="primary"
              size="16"
            >
              mdi-email
            </v-icon>
            <a href="mailto:info@verstappen.be">info@verstappen.be</a>
          </span>
        </v-col>
        <v-col
          cols="12"
          lg="12"
        >
          <div
            class="float-right"
          >
            <v-btn
              v-for="(item, index) in ComputedItems"
              class="headerButton"
              depressed
              :key="index"
              size="small"
              tile
              :to="item.to"
            >
              {{ item.title }}
            </v-btn>
            <v-btn   
              class="bg-primary"           
              depressed
              size="small"
              tile
              :to="LogItem.to"
            >
              {{ LogItem.title }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-app-bar
      v-if="AuthenticationMixin_LoggedIn"
      class="hidden-sm-and-down"
      color="primary"
      fixed
      :height="heights.appbar2"
      id="header1-2"
      :style="`top: ${heights.appbar1}px !important;`"
    >
      <div
        class="center"
      >
        <v-btn
          v-for="(item, index) in ItemsMapped(portaal.items)"
          class="headerButtonPrimary"
          color="transparent"
          depressed
          :key="index"
          size="small"
          tile
          :to="item.to"
        >
          {{ item.title }}
        </v-btn>
        |
        <v-btn
          v-for="(item, index) in ItemsMapped(portaal.items2)"
          class="headerButtonPrimary"
          color="transparent"
          depressed
          :key="`${index}-2`"
          size="small"
          tile
          :to="item.to"
        >
          {{ item.title }}
        </v-btn>
        <v-btn
          class="headerButtonPrimary"
          depressed
          size="small"
          tile
          :to="LogItem.to"
        >
          {{ LogItem.title }}
        </v-btn>
      </div>
    </v-app-bar>
    <v-app-bar
      v-if="$store.state.headers.raadplegen || $store.state.headers.samenstellen"
      class="hidden-sm-and-down"
      color="white"
      :height="heights.appbar3"
      fixed
      id="header1-3"
      :style="`top: ${heights.appbar1 + heights.appbar2}px !important;`"
    >
      <div
        class="center"
      >
        <v-btn
          class="ml-1 mt-1 secondaryLink"
          depressed
          size="small"
          tile
          to="Uitvaart"
        >
          {{ $store.state.headers.raadplegen ? 'Raadplegen' : $store.state.headers.samenstellen ? 'Samenstellen' : null }}
        </v-btn>
        <v-btn
          v-for="(item, index) in ItemsMapped(ComputedItemsUitvaart)"
          class="headerButtonPrimary ml-1 mt-1"
          depressed
          :key="index"
          size="small"
          :style="`background-color: ${item.color}`"
          tile
          :to="item.to"
        >
          {{ item.title }}
        </v-btn>
      </div>
    </v-app-bar>
    <v-app-bar
      class="hidden-md-and-up"
      color="white"
      fixed
      :height="heights.appbar1"
      id="header2"
      style="top: 0 !important;"
    >
      <v-app-bar-title>
        <v-img
          class="logo"
          :src="require('@/assets/images/logo.png')"
          @click="Home()"
        />
      </v-app-bar-title>
      <v-spacer/>
      <v-app-bar-nav-icon
        @click="dialog = !dialog"
      />
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-app-bar color="white" style="top: 0 !important;">
            <v-app-bar-title>
              Navigatie
            </v-app-bar-title>
            <v-spacer/>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-app-bar>
          <v-list style="top: 50px !important">
            <v-list-item
              v-for="(item, index) in ComputedItems"
              :key="index"
              :to="item.to"
              @click="dialog = false"
            >
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :to="LogItem.to"
              @click="dialog = false"
            >
              <v-list-item-title>
                {{ LogItem.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-dialog>
    </v-app-bar>
    <v-app-bar
      v-if="AuthenticationMixin_LoggedIn"
      class="hidden-md-and-up"
      color="primary"
      fixed
      :height="heights.appbar2"
      id="header2-2"
      :style="`top: ${heights.appbar1}px !important;`"
    >
      <v-app-bar-title>
        Klantportaal
      </v-app-bar-title>
      <v-spacer/>
      <v-app-bar-nav-icon
        @click="dialogPortaal = !dialogPortaal"
      />
      <v-dialog
        v-model="dialogPortaal"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-app-bar color="white" style="top: 0 !important;">
            <v-app-bar-title>
              Klantportaal
            </v-app-bar-title>
            <v-spacer/>
            <v-btn icon @click="dialogPortaal = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-app-bar>
          <v-list style="top: 50px !important;">
            <v-list-item
              v-for="(item, index) in ComputedItemsPortaal"
              :key="index"
              :to="item.to"
              @click="dialogPortaal = false"
            >
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :to="LogItem.to"
              @click="dialogPortaal = false"
            >
              <v-list-item-title>
                {{ LogItem.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-dialog>
    </v-app-bar>
    <v-app-bar
      v-if="$store.state.headers.raadplegen || $store.state.headers.samenstellen"
      class="hidden-md-and-up"
      color="white"
      :height="heights.appbar3"
      fixed
      id="header2-3"
      :style="`top: ${heights.appbar1 + heights.appbar2}px !important;`"
    >
      <v-app-bar-title>
        {{ Header3Title }}
      </v-app-bar-title>
      <v-spacer/>
      <v-app-bar-nav-icon
        @click="dialogUitvaart = !dialogUitvaart"
      />
      <v-dialog
        v-model="dialogUitvaart"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-app-bar color="white" style="top: 0 !important;">
            <v-app-bar-title>
              {{ Header3Title }}
            </v-app-bar-title>
            <v-spacer/>
            <v-btn icon @click="dialogUitvaart = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-app-bar>
          <v-list style="top: 50px !important;">
            <v-list-item
              to="Uitvaart"
              @click="dialogUitvaart = false"
            >
              <v-list-item-title>
                Uitvaart
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-for="(item, index) in ComputedItemsUitvaart"
              :key="index"
              :to="item.to"
              @click="dialogUitvaart = false"
            >
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-dialog>
    </v-app-bar>
  </v-container>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'
import commonMixin from '@/mixins/commonMixin'
import errorMixin from '@/mixins/errorMixin'

export default {
  name: 'TheHeader',
  mixins: [
    authenticationMixin,
    commonMixin,
    errorMixin
  ],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      account: undefined,
      dialog: false,
      dialogPortaal: false,
      dialogUitvaart: false,
      items: [
        {
          title: 'Startpagina',
          to: 'Home'
        },
        {
          title: 'Overlijdensberichten',
          to: 'Overlijdensberichten'
        },
        {
          title: 'Funeraria',
          to: 'Funeraria'
        },
        {
          title: 'Aula\'s',
          to: 'Aulas'
        },
        {
          title: 'Contact',
          to: 'Contact'
        },
        {
          title: 'Nieuws',
          to: 'Nieuws'
        },
        {
          title: 'Bereid je uitvaart voor',
          to: 'BereidJeUitvaartVoor'
        }
      ],
      heights: {
        appbar1: 100,
        appbar2: 40,
        appbar3: 40
      },
      loginItem: {
        to: 'Login',
        title: 'Aanmelden'
      },
      logoutItem: {
        to: 'Logout',
        title: 'Afmelden'
      },
      portaal: {
        items: [
          {
            title: 'Uitvaart',
            to: 'Uitvaart'
          },
          {
            title: 'Administratie',
            to: 'Administratie'
          },
          // {
          //   title: 'Mijn overzicht',
          //   to: 'Overzicht'
          // },
          {
            title: 'Mijn mening',
            to: 'Enquete'
          // },
          // {
          //   title: 'Bericht sturen',
          //   to: 'Bericht'
          }
        ],
        items2: [
          {
            title: 'Uw account',
            to: 'Account'
          }
        ]
      },
      raadplegen: {
        items: [
          {
            color: '#424E58',
            title: 'Condoleances',
            to: 'Condoleances'
          },
          {
            color: '#424E58',
            title: 'Aanwezigen koffietafel',
            to: 'Koffietafel'
          },
          {
            color: '#424E58',
            title: 'Kostenoverzicht',
            to: 'Facturatie'
          // },
          // {
          //   color: '#424E58',
          //   title: 'Maatregelen COVID-19',
          //   to: 'COVID19'
          }
        ]
      },
      samenstellen: {
        items: [
          {
            color: '#DEC067',
            title: 'Draaiboek uitvaart',
            to: 'Draaiboek'
          },
          {
            color: '#DEC067',
            title: 'Rouwdrukwerk',
            to: 'Rouwdrukwerk'
          },
          {
            color: '#DEC067',
            title: 'Muziek uitvaart',
            to: 'Muziek'
          },
          {
            color: '#DEC067',
            title: 'Publicaties',
            to: 'Publicaties'
          },
          {
            color: '#DEC067',
            title: 'Bloemen',
            to: 'Bloemen'
          },
          {
            color: '#DEC067',
            title: 'Urne',
            to: 'Urne'
          },
          {
            color: '#DEC067',
            title: 'Mini-urne',
            to: 'MiniUrne'
          },
          {
            color: '#DEC067',
            title: 'Grafmonumenten',
            to: 'Grafmonumenten'
          },
          {
            color: '#DEC067',
            title: 'Herinneringsjuwelen',
            to: 'Herinneringsjuwelen'
          }
        ]
      }
    }
  },
  computed: {
    ComputedItems () {
      return this.items.map(this.MapItem)
    },
    ComputedItemsPortaal () {
      return this.portaal.items.map(this.MapItem)
    },
    ComputedItemsUitvaart () {
      const list = this.$store.state.headers.raadplegen ? this.raadplegen.items : this.$store.state.headers.samenstellen ? this.samenstellen.items : [] ?? []
      return list.map(this.MapItem)
    },
    Header3Title () {
      return this.$store.state.headers.raadplegen ? 'Raadplegen' : this.$store.state.headers.samenstellen ? 'Samenstellen' : null
    },
    LogItem () {
      return this.AuthenticationMixin_LoggedIn ? this.logoutItem : this.loginItem
    },
    MarginBottom () {
      let margin = this.heights.appbar1 - 12

      if (this.AuthenticationMixin_LoggedIn) {
        margin += this.heights.appbar2
      }

      if (this.$store.state.headers.raadplegen || this.$store.state.headers.samenstellen) {
        margin += this.heights.appbar3
      }

      return `${margin - 20}px`
    },
    Profile () {
      return {
        avatar: true,
        title: this.$t('avatar')
      }
    }
  },
  methods: {
    Home () {
      if (this.$route.path !== '/') {
        this.$router.push({ path: '/' })
      }
    },
    ItemsMapped (items) {
      return items.map(this.MapItem)
    },
    MapItem (item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.MapItem) : undefined,
        title: item.title
      }
    }
  }
}
</script>
