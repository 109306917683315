<template>
  <v-row
    class="center"
  >
    <v-col
      v-for="(locatie, index) in locationMixin.locaties.filter(item => item[name.toLowerCase()])"
      :key="index"
      class="center mb-3"
      cols="12"
      lg="4"
    >
      <div
        class="mx-3"
      >
        <div
          class="clickAction text-center text-uppercase"
          style="max-width: 400px;"
          @click="$emit('locatie', locatie.locatie)"
        >
          <v-img
            :src="require(`@/assets/images/${name} ${locatie.locatie}.jpg`)"
          />
          <div
            class="imageBottomOverlayText py-5"
          >
            {{ locatie.locatie }}
          </div>
        </div>
        <p>
          <span
            class="font-weight-bold"
          >
            {{ locatie.type }} {{locatie.locatie}}
          </span>
          <br>
          {{ locatie.adres.straat }} {{locatie.adres.huisnummer}}
          <br>
          {{ locatie.contact.telefoonnummer }}
        </p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import locationMixin from '@/mixins/locationMixin'

export default {
  name: 'LocatieList',
  mixins: [
    locationMixin
  ],
  props: {
    name: String
  }
}
</script>
