<template>
  <publicaties />
</template>

<script>
import Publicaties from '../components/Publicaties.vue'

export default {
  name: 'PublicatiesView',
  components: {
    Publicaties
  }
}
</script>
