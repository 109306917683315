export default {
  data: () => ({
    sessionStorageFilterKey: 'VerstappenNVFilters'
  }),
  methods: {
    async CacheMixin_GetCachedElement (key, fallbackMethod) {
      const cachedElement = sessionStorage.getItem(key)

      if (cachedElement && cachedElement !== 'undefined') {
        return JSON.parse(cachedElement)
      } else {
        const element = await fallbackMethod()
        sessionStorage.setItem(key, JSON.stringify(element))
        return element
      }
    },
    CacheMixin_GetFilters () {
      this.$store.state.filters = JSON.parse(sessionStorage.getItem(this.sessionStorageFilterKey)) ?? this.$store.state.filters

      if (!this.$store.state.filters[this.$route.name]) {
        this.$store.state.filters[this.$route.name] ??= {}
        location.reload()
      }

      this.$store.state.filters.loaded = true
    },
    async CacheMixin_ManuallyAddKeys (keys = []) {
      let isReloadNeeded = false

      keys.forEach(key => {
        if (!Object.prototype.hasOwnProperty.call(this.$store.state.filters[this.$route.name], key)) {
          this.$store.state.filters[this.$route.name][key] = null
          isReloadNeeded = true
        }
      })

      if (isReloadNeeded) {
        await this.CacheMixin_SetFilters()
        location.reload()
      }
    },
    async CacheMixin_SetFilters () {
      await sessionStorage.setItem(this.sessionStorageFilterKey, JSON.stringify(this.$store.state.filters))
    }
  }
}
