import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/dist/vuetify.js'

const myCustomLightTheme = {
  dark: false,
  colors: {
    primary: '#907322',
    secondary: '#424E58',
    accent: '#9C27b0',
    info: '#00CAE3'
  },
}

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    },
  }
})

export default vuetify
