<template>
  <contact />
</template>

<script>
import Contact from '../components/Contact.vue'

export default {
  name: 'ContactView',
  components: {
    Contact
  }
}
</script>
