<template>
  <v-container
    v-if="authenticationMixin.checkedLogin"
    class="content pa-0"
    fluid
  >
    <v-row
      class="alternatingContentRow pa-0"
    >
    </v-row>
    <v-row
      class="alternatingContentRow my-8 py-8"
    >
      <v-col
        class="center"
        cols="12"
        lg="10"
      >
        <webshop
          v-if="dossier"
          :betalen="false"
          :dossier="dossier"
          logged-in
          type="urne"
          @success="WebshopSuccess($event)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'
import commonMixin from '@/mixins/commonMixin'
import errorMixin from '@/mixins/errorMixin'
import klantService from '@/services/klantService'
import Webshop from './Webshop.vue'

export default {
  name: 'UrneComponent',
  mixins: [
    authenticationMixin,
    commonMixin,
    errorMixin
  ],
  components: {
    Webshop
  },
  data () {
    return {
      dossier: null
    }
  },
  mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.AuthenticationMixin_CheckIfLoggedIn()
    this.$store.state.headers.samenstellen = true
    klantService.Init(this)
    this.GetDossier()
  },
  beforeUnmount () {
    this.$store.state.headers.samenstellen = false
  },
  methods: {
    async GetDossier () {
      this.CommonMixin_Load(this.$options.name, true)
      const resp = await klantService.GetDossier()

      if (resp && (resp.data || resp.data !== '')) {
        this.dossier = resp.data
      }

      this.CommonMixin_Load(this.$options.name, false)
    },
    WebshopSuccess () {
      this.$router.push('/Uitvaart')
    }
  }
}
</script>
