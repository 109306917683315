import moment from 'moment'

export default {
  data () {
    return {
      validationMixin: {
        rules: {
          belgianNationalNumber: value => {
            let valid = false
            const pattern = /^[0-9]{2}[.][0-9]{2}[.][0-9]{2}[-][0-9]{3}[.][0-9]{2}$/

            if (pattern.test(value)) {
              const number = value.replaceAll('.', '').replaceAll('-', '')
              const remainder = parseInt(number.substr(9, 2))
              let firstPart = parseInt(number.substr(0, 9))
              let checkPart = 97 - (firstPart % 97)

              if (checkPart !== remainder) {
                // If birth date is after 2000 add: add a 2 before the first 9 numbers
                firstPart = 2000000000 + firstPart
                checkPart = 97 - (firstPart % 97)
              }

              if (checkPart === remainder) {
                valid = true
              }
            }

            return (!value || valid) || 'Ongeldig rijksregisternummer.'
          },
          date: value => {
            const pattern = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](18|19|20|21)\d\d$/
            return (!value || (pattern.test(value) && moment(value, 'DD/MM/YYYY').isValid())) || 'Ongeldige datum.'
          },
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return (!value || pattern.test(value)) || 'Ongeldig e-mailadres.'
          },
          guid: value => {
            const pattern = /^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$/
            return (!value || pattern.test(value))
          },
          max10Characters: value => (!value || value.length <= 10) || 'Maximaal 10 tekens.',
          max100Characters: value => (!value || value.length <= 100) || 'Maximaal 100 tekens.',
          max20Characters: value => (!value || value.length <= 20) || 'Maximaal 20 tekens.',
          max255Characters: value => (!value || value.length <= 255) || 'Maximaal 255 tekens.',
          max50Characters: value => (!value || value.length <= 50) || 'Maximaal 50 tekens.',
          max500Characters: value => (!value || value.length <= 500) || 'Maximaal 500 tekens.',
          min8Characters: value => (!value || value.length >= 8) || 'Minimaal 8 tekens.',
          naturalNumber: value => {
            const pattern = /^(?:0|[1-9][0-9]*)$/
            return (!value || pattern.test(value)) || 'Ongeldig getal.'
          },
          password: value => {
            const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W])/
            return (!value || pattern.test(value)) || 'Uw wachtwoord moet minstens één kleine letter, hoofdletter, cijfer en speciaal teken bevatten.'
          },
          required: value => !!value || 'Vereist.',
          requiredCheckbox: value => value != null || 'Vereist.',
          vatNumber: value => {
            let valid = false

            if (value) {
              const patternBelgium = /^((BE)0[0-9]{9})/
              const trimCharacters = [' ', '.', '-']
              let valueTrimmed = value

              trimCharacters.forEach(character => {
                valueTrimmed = valueTrimmed.replaceAll(character, '')
              })

              if (patternBelgium.test(valueTrimmed)) {
                const number = valueTrimmed.replaceAll('BE', '')
                const remainder = parseInt(number.substr(8))
                const firstPart = parseInt(number.substr(0, 8))
                const checkPart = 97 - (firstPart % 97)
                valid = checkPart === remainder
              } else {
                const pattern = new RegExp([
                  '^(',
                  '(AT)U[0-9]{8}|', // Austria
                  '(BG)[0-9]{9,10}|', // Bulgaria
                  '(CY)[0-9]{8}L|', // Cyprus
                  '(CZ)[0-9]{8,10}|', // Czech Republic
                  '(DE)[0-9]{9}|', // Germany
                  '(DK)[0-9]{8}|', // Denmark
                  '(EE)[0-9]{9}|', // Estonia
                  '(EL|GR)[0-9]{9}|', // Greece
                  '(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|', // Spain
                  '(FI)[0-9]{8}|', // Finland
                  '(FR)[0-9A-Z]{2}[0-9]{9}|', // France
                  '(GB)([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|', // United Kingdom
                  '(HU)[0-9]{8}|', // Hungary
                  '(IE)[0-9]S[0-9]{5}L|', // Ireland
                  '(IT)[0-9]{11}|', // Italy
                  '(LT)([0-9]{9}|[0-9]{12})|', // Lithuania
                  '(LU)[0-9]{8}|', // Luxembourg
                  '(LV)[0-9]{11}|', // Latvia
                  '(MT)[0-9]{8}|', // Malta
                  '(NL)[0-9]{9}B[0-9]{2}|', // Netherlands
                  '(PL)[0-9]{10}|', // Poland
                  '(PT)[0-9]{9}|', // Portugal
                  '(RO)[0-9]{2,10}|', // Romania
                  '(SE)[0-9]{12}|', // Sweden
                  '(SI)[0-9]{8}|', // Slovenia
                  '(SK)[0-9]{10}|', // Slovakia
                  ')$'
                ].join(''))

                valid = pattern.test(valueTrimmed)
              }
            }

            return (!value || valid) || 'Ongeldig BTW-nummer.'
          }
        }
      }
    }
  }
}
