<template>
  <v-container
    v-if="authenticationMixin.checkedLogin"
    class="content pa-0"
    fluid
  >
    <v-row>
      <v-col
        class="center pt-8"
        cols="12"
        lg="12"
      >
        <v-row
          justify="center"
        >
          <v-col
            class="text-center"
            cols="12"
            lg="8"
          >
            <h2
              class="text-center"
            >
              Publicaties
            </h2>
            <p
              v-if="listMixin.list.length === 0"
            >
              Er zijn momenteel geen publicaties om weer te geven.
            </p>
          </v-col>
        </v-row>
        <v-row
          v-for="row in listMixin.list.length"
          class="mt-2 py-8 px-3"
          :class="row % 2 === 1 ? 'alternatingContentRow2' : ''"
          justify="center"
          :key="row"
        >
          <v-col
            cols="12"
            lg="2"
          >
            <v-btn
              color="primary"
              :disabled="!listMixin.list[row - 1].idBestand"
              tile
              @click="Download(listMixin.list[row - 1])"
            >
              Download
            </v-btn>
          </v-col>
          <v-col
            class="py-2"
            cols="12"
            lg="2"
          >
            {{ FormatMixin_FormatDateTime(listMixin.list[row - 1].datumPublicatie, formatMixin.date.database, formatMixin.date.date) }}
          </v-col>
          <v-col
            class="py-2"
            cols="12"
            lg="2"
          >
            {{ listMixin.list[row - 1].typeCode.longDescription }}
          </v-col>
          <v-col
            class="py-2"
            cols="12"
            lg="2"
          >
            {{ listMixin.list[row - 1].krantCode.longDescription }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'
import blobMixin from '@/mixins/blobMixin'
import commonMixin from '@/mixins/commonMixin'
import constantMixin from '@/mixins/constantMixin'
import errorMixin from '@/mixins/errorMixin'
import listMixin from '@/mixins/listMixin'
import klantService from '@/services/klantService'
import rapportService from '@/services/rapportService'

export default {
  name: 'CondoleancesComponent',
  mixins: [
    authenticationMixin,
    blobMixin,
    commonMixin,
    constantMixin,
    errorMixin,
    listMixin
  ],
  mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.AuthenticationMixin_CheckIfLoggedIn()
    this.$store.state.headers.samenstellen = true
    klantService.Init(this)
    rapportService.Init(this)
    this.GetUitvaartOverlijdensberichtPublicatieList()
  },
  beforeUnmount () {
    this.$store.state.headers.samenstellen = false
  },
  methods: {
    async Download (publicatie) {
      this.CommonMixin_Load(this.$options.name, true)
      const blob = {
        bestandsnaam: `${publicatie.typeCode.longDescription} ${publicatie.krantCode.longDescription}`,
        idBlob: publicatie.idBestand
      }

      await this.BlobMixin_DownloadExisting(blob)
      this.CommonMixin_Load(this.$options.name, false)
    },
    async GetUitvaartOverlijdensberichtPublicatieList () {
      this.CommonMixin_Load(this.$options.name, true)
      const resp = await klantService.GetUitvaartOverlijdensberichtPublicatieList()

      if (resp && (resp.data || resp.data !== '')) {
        this.listMixin.list = resp.data
      }

      this.CommonMixin_Load(this.$options.name, false)
    }
  }
}
</script>
