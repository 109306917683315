<template>
  <bloemen />
</template>

<script>
import Bloemen from '../components/Bloemen.vue'

export default {
  name: 'BloemenView',
  components: {
    Bloemen
  }
}
</script>
