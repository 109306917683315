<template>
  <bereid-je-uitvaart-voor />
</template>

<script>
import BereidJeUitvaartVoor from '../components/BereidJeUitvaartVoor.vue'

export default {
  name: 'BereidJeUitvaartVoorView',
  components: {
    BereidJeUitvaartVoor
  }
}
</script>
