<template>
  <nieuws />
</template>

<script>
import Nieuws from '../components/Nieuws'

export default {
  name: 'NieuwsView',
  components: {
    Nieuws
  }
}
</script>
