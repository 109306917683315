<template>
  <grafmonumenten />
</template>

<script>
import Grafmonumenten from '../components/Grafmonumenten.vue'

export default {
  name: 'GrafmonumentenView',
  components: {
    Grafmonumenten
  }
}
</script>
