<template>
  <v-container
    v-if="authenticationMixin.checkedLogin"
    class="content pa-0"
    fluid
  >
    <v-row
      class="alternatingContentRow pa-0"
    >
      <v-col
        cols="12"
        lg="12"
      >
        <v-img
          :src="require(`@/assets/images/ExtraDiensten banner.jpg`)"
        />
      </v-col>
    </v-row>
    <v-row
      justify="center"
    >
      <v-col
        class="center my-8 py-8"
        cols="12"
        lg="8"
      >
        <h1
          class="text-center"
        >
          Grafmonumenten
        </h1>
        <p
          class="text-center"
        >
          Bij een overlijden begeleiden we u ook bij de keuze van een grafmonument.
          <br>
          Hier vindt u alvast een impressie uit ons assortiment.
        </p>
      </v-col>
      <v-col
        class="center my-8 py-8"
        cols="12"
        lg="6"
      >
        <template
          v-for="(item, index) in listMixin.list"
          :key="index"
        >
          <v-row>
            <v-col
              cols="12"
              lg="1"
            >
              <v-btn
                block
                color="primary"
                tile
                @click="Download(item)"
              >
                <v-icon>
                  mdi-download
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              lg="8"
            >
              <v-btn
                block
                style="overflow: hidden; justify-content: flex-start !important;"
                tile
              >
                {{ item.bestand.bestandsnaam }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              lg="3"
            >
              <v-btn
                block
                color="primary"
                :disabled="item.geselecteerdDoorKlant"
                tile
                @click="Selecteer(item)"
                >
                Bevestig keuze
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col
            cols="12"
            lg="1"
          >
            <v-btn
              block
              color="secondary"
              tile
              @click="DownloadAlle()"
            >
              <v-icon>
                mdi-download
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'
import blobMixin from '@/mixins/blobMixin'
import commonMixin from '@/mixins/commonMixin'
import constantMixin from '@/mixins/constantMixin'
import errorMixin from '@/mixins/errorMixin'
import listMixin from '@/mixins/listMixin'
import blobService from '@/services/blobService'
import klantService from '@/services/klantService'

export default {
  name: 'GrafmonumentenComponent',
  mixins: [
    authenticationMixin,
    blobMixin,
    commonMixin,
    constantMixin,
    errorMixin,
    listMixin
  ],
  mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.AuthenticationMixin_CheckIfLoggedIn()
    this.$store.state.headers.samenstellen = true
    blobService.Init(this)
    klantService.Init(this)
    this.GetUitvaartGrafmonumentenList()
  },
  beforeUnmount () {
    this.$store.state.headers.samenstellen = false
  },
  methods: {
    async Download (grafmonument) {
      this.CommonMixin_Load(this.$options.name, true)
      const blob = {
        bestandsnaam: grafmonument.bestand.bestandsnaam,
        idBlob: grafmonument.idBlob
      }

      await this.BlobMixin_DownloadExisting(blob)
      this.CommonMixin_Load(this.$options.name, false)
    },
    async DownloadAlle () {
      this.CommonMixin_Load(this.$options.name, true)
      await this.BlobMixin_DownloadZip(this.listMixin.list.map(x => x.idBlob))
      this.CommonMixin_Load(this.$options.name, false)
    },
    async GetUitvaartGrafmonumentenList () {
      this.CommonMixin_Load(this.$options.name, true)
      const resp = await klantService.GetUitvaartGrafmonumentenList()

      if (resp && (resp.data || resp.data !== '')) {
        this.listMixin.list = resp.data
      }

      this.CommonMixin_Load(this.$options.name, false)
    },
    async Selecteer (grafmonument) {
      this.CommonMixin_Load(this.$options.name, true)
      const resp = await klantService.SelecteerUitvaartGrafmonument(grafmonument.idDossierGrafmonumentBestand)

      if (resp && (resp.data || resp.data !== '')) {
        await this.GetUitvaartGrafmonumentenList()
      }

      this.CommonMixin_Load(this.$options.name, false)
    }
  },
}
</script>
