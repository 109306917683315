import commonMixin from '@/mixins/commonMixin'

export default {
  mixins: [
    commonMixin
  ],
  data () {
    return {
      listMixin: {
        detailPage: '',
        filter: '',
        headers: [],
        itemsPerPage: 30,
        key: '',
        list: [],
        listFiltered: [],
        selected: null
      }
    }
  },
  methods: {
    ListMixin_Add () {
      this.$router.push({ path: this.listMixin.detailPage, query: { returnUrl: this.returnUrl } })
    },
    ListMixin_Filter (list) {
      this.listMixin.listFiltered = list
      this.$forceUpdate()
    },
    ListMixin_OpenDetailPage (element) {
      this.$router.push({ path: this.listMixin.detailPage, query: { returnUrl: this.returnUrl, [this.listMixin.key]: element[this.listMixin.key] } })
    },
    ListMixin_Select (item) {
      if (this.ListMixin_Selected(item)) {
        this.listMixin.selected = null
      } else {
        this.listMixin.selected = item
      }
    },
    ListMixin_Selected (item) {
      return this.listMixin.selected != null && this.listMixin.selected === item
    }
  }
}
