<template>
  <v-container
    class="content pa-0"
    fluid
  >
    <v-row
      class="alternatingContentRow pa-0"
    >
      <v-col
        cols="12"
        lg="12"
      >
        <v-img
          :src="require(`@/assets/images/Contact banner.jpg`)"
        />
      </v-col>
    </v-row>
    <v-row
      class="alternatingContentRow"
    >
      <v-col
        class="center my-8 py-8"
        cols="12"
        lg="8"
      >
        <h1
          class="text-center"
        >
          Contactgegevens
        </h1>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            lg="6"
          >
            <p>
              <span
                class="font-weight-bold"
              >
                Meld een overlijden:
              </span>
              014 58 84 65
              <br>
              <span
                class="font-weight-bold"
              >
                Mail ons:
              </span>
              <a
                href="mailto:info@verstappen.be"
              >
                info@verstappen.be
              </a>
            </p>
            <p>
              Stuur een bericht naar Verstappen uitvaartzorg, maak een afspraak voor een overlijdensverzekering of voor een voorgesprek.
            </p>
            <v-row>
              <v-col
                cols="12"
                lg="8"
              >
                <v-form
                  v-model="valid"
                  ref="form"
                >
                  <v-autocomplete
                    v-model="bericht.geadresseerde"
                    base-color="primary"
                    bg-color="white"
                    color="primary"
                    density="compact"
                    item-value="value"
                    item-title="text"
                    :items="Geadresseerden"
                    label="Aan wie richt u het bericht?"
                    variant="outlined"
                    :rules="[validationMixin.rules.required]"
                  />
                  <v-text-field
                    v-model="bericht.naam"
                    base-color="primary"
                    bg-color="white"
                    color="primary"
                    density="compact"
                    label="Uw naam"
                    variant="outlined"
                    :rules="[validationMixin.rules.max50Characters, validationMixin.rules.required]"
                  />
                  <v-text-field
                    v-model="bericht.postcode"
                    base-color="primary"
                    bg-color="white"
                    color="primary"
                    density="compact"
                    label="Uw postcode"
                    variant="outlined"
                    :rules="[validationMixin.rules.max50Characters, validationMixin.rules.required]"
                  />
                  <v-text-field
                    v-model="bericht.emailadres"
                    base-color="primary"
                    bg-color="white"
                    color="primary"
                    density="compact"
                    label="Uw e-mailadres"
                    variant="outlined"
                    :rules="[validationMixin.rules.email, validationMixin.rules.max50Characters, validationMixin.rules.required]"
                  />
                  <v-text-field
                    v-model="bericht.telefoonnummer"
                    base-color="primary"
                    bg-color="white"
                    color="primary"
                    density="compact"
                    label="Uw telefoonnummer"
                    variant="outlined"
                    :rules="[validationMixin.rules.max50Characters, validationMixin.rules.required]"
                  />
                  <v-textarea
                    v-model="bericht.bericht"
                    auto-grow
                    base-color="primary"
                    bg-color="white"
                    color="primary"
                    density="compact"
                    label="Uw bericht"
                    variant="outlined"
                    :rules="[validationMixin.rules.max500Characters, validationMixin.rules.required]"
                  />
                  <!-- <v-row>
                    <v-col
                      cols="12"
                      lg="12"
                    >
                      <v-checkbox
                        v-model="bericht.akkoord"
                        class="mt-0"
                        base-color="primary"
                        bg-color="white"
                        color="primary"
                        :rules="[validationMixin.rules.required]"
                      >
                        <span
                          slot="label"
                        >
                          Door dit formulier te verzenden gaat u akkoord met de verwerking van de door u opgegeven gegevens zoals bepaald in ons <a class="secondaryLink text-decoration-underline" @click="$router.push('Privacybeleid')" >privacybeleid</a>.
                        </span>
                      </v-checkbox>
                    </v-col>
                  </v-row> -->
                  <v-row>
                    <v-col
                      cols="12"
                      lg="12"
                    >
                      <vue-recaptcha
                        v-if="reCaptchaKey"
                        :sitekey="reCaptchaKey"
                        @error="bericht.robot = false"
                        @expired="bericht.robot = false"
                        @render="bericht.robot = false"
                        @verify="bericht.robot = true"
                      />
                    </v-col>
                  </v-row>
                </v-form>
                <v-btn
                  class="mt-5"
                  color="primary"
                  tile
                  @click="Verzend()"
                >
                  Verzend bericht
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            lg="3"
          >
            <h2
              class="pb-0 text-uppercase"
            >
              Onze locaties
            </h2>
            <p
              v-for="(locatie, index) in locationMixin.locaties"
              :key="index"
            >
              <a
                class="text-decoration-underline text-uppercase"
                @click="$router.push(`Funeraria?locatie=${locatie.locatie}`)"
              >
                {{ locatie.locatie }}
              </a>
              <br>
              {{ locatie.adres.straat }} {{ locatie.adres.huisnummer }}
              <br>
              {{ locatie.contact.telefoonnummer }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="succeeded"
      width="400px"
      persistent
    >
      <v-card
        class="pa-3"
      >
        <h2
          class="colorSecondary text-center"
        >
          Bericht verstuurd
        </h2>
        <p
          class="colorSecondary text-center"
        >
          {{ messages.success }}
          <br>
          Klik op 'OK' om terug te gaan naar de home-pagina.
          <v-btn
            class="mt-5"
            color="primary"
            tile
            to="/"
          >
            OK
          </v-btn>
        </p>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import commonMixin from '@/mixins/commonMixin'
import errorMixin from '@/mixins/errorMixin'
import locationMixin from '@/mixins/locationMixin'
import textMixin from '@/mixins/textMixin'
import validationMixin from '@/mixins/validationMixin'
import configurationService from '@/services/configurationService'
import klantService from '@/services/klantService'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  name: 'ContactComponent',
  mixins: [
    commonMixin,
    errorMixin,
    locationMixin,
    textMixin,
    validationMixin
  ],
  components: {
    VueRecaptcha
  },
  data () {
    return {
      bericht: {
        akkoord: false,
        bericht: '',
        geadresseerde: null,
        emailadres: '',
        naam: '',
        postcode: '',
        robot: false,
        telefoonnummer: ''
      },
      messages: {
        success: ''
      },
      reCaptchaKey: null,
      succeeded: false,
      valid: false
    }
  },
  mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    configurationService.Init(this)
    klantService.Init(this)
    this.GetConfiguration()
  },
  computed: {
    Geadresseerden () {
      const geadresseerden = [
        {
          text: 'Algemeen',
          value: 'Algemeen'
        }
      ]

      this.locationMixin.locaties.forEach(locatie => {
        geadresseerden.push({
          text: locatie.locatie,
          value: locatie.locatie
        })
      })

      return geadresseerden.sort((a, b) => (a.text > b.text) ? 1 : -1)
    }
  },
  methods: {
    async GetConfiguration () {
      const resp = await configurationService.GetReCaptchaSiteKey()

      if (resp && (resp.data || resp.data !== '')) {
        this.reCaptchaKey = resp.data
      }
    },
    async Verzend () {
      this.$refs.form.validate()

      if (this.valid) {
        if (this.bericht.robot) {
          this.CommonMixin_Load(this.$options.name, true)

          const resp = await klantService.SendContactBericht(this.bericht)
          const message = 'Uw bericht werd naar ons verstuurd.'

          if (resp && (resp.data || resp.data !== '')) {
            this.messages.success = message
            this.succeeded = true
          }

          this.CommonMixin_Load(this.$options.name, false)
        } else {
          this.CommonMixin_Snackbar(this.textMixin.messages.captcha, 'error')
        }
      }
    }
  }
}
</script>
