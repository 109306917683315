<template>
  <locaties
    name="Funeraria"
  />
</template>

<script>
import Locaties from '../components/Locaties.vue'

export default {
  name: 'FunerariaView',
  components: {
    Locaties
  }
}
</script>
