import axios from 'axios'

let _svc
let _that

export default {
  Init: (that) => {
    _that = that

    _svc = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    _svc.interceptors.response.use(
      response => response,
      error => {
        _that.ErrorMixin_HandleError(error)
      }
    )
  },
  Download: (idRapport, idDossier, dateTime) => {
    try {
      const parameters = {
        dateTime: dateTime,
        idDossier: idDossier,
        idRapport: idRapport
      }

      return _svc.post('Rapport/Download', parameters, {
        responseType: 'blob'
      })
    } catch {
      return null
    }
  }
}
