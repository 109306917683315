<template>
  <v-layout
    class="alternatingContentRow"
    fill-height
  >
    <v-container
      class="px-3"
      fluid
      tag="section"
    >
      <v-row>
        <v-col
          class="center"
          cols="12"
          lg="6"
        >
          <v-card
            v-if="error != null"
            class="px-5"
          >
            <v-card-title>
              <h1
                class="my-5"
              >
                ERROR {{ error.code != 0 ? error.code : ''}}
              </h1>
            </v-card-title>

            <v-card-subtitle>
              {{ error.description }}
            </v-card-subtitle>

            <v-card-text>
              {{ error.text }}
              <br>
              {{ redirect }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'
import errorMixin from '@/mixins/errorMixin'

export default {
  name: 'ErrorDetail',
  mixins: [
    authenticationMixin,
    errorMixin
  ],
  data () {
    return {
      doRedirect: false,
      error: null,
      redirect: ''
    }
  },
  mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.GetError()
    this.Redirect()
  },
  methods: {
    GetError () {
      if (this.$route.query != null && this.$route.query.code != null && this.$route.query.code !== '') {
        this.error = this.ErrorMixin_GetError(this.$route.query.code)
      } else {
        this.$router.push({ path: 'Error', query: { code: 404 } })
        this.error = this.ErrorMixin_GetError(this.$route.query.code)
      }
    },
    Redirect () {
      if (this.doRedirect) {
        this.redirect += 'U wordt automatisch doorgestuurd naar de homepagina.'
        setTimeout(() => this.$router.push({ path: '/' }), 2000)
      }
    }
  }
}
</script>
