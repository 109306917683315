<template>
  <uitvaart />
</template>

<script>
import Uitvaart from '../components/Uitvaart.vue'

export default {
  name: 'UitvaartView',
  components: {
    Uitvaart
  }
}
</script>
