<template>
  <v-container
    class="content pa-0"
    fluid
  >
    <v-row
      class="alternatingContentRow pa-0"
    >
      <v-col
        cols="12"
        lg="12"
        style="position: relative"
      >
        <v-img
          :src="require(`@/assets/images/Home banner.gif`)"
        />
        <p
          class="homeBannerText"
        >
          De eerste stappen bij verlies
          <br>
          <span
            class="font-italic"
          >
            We steunen, we luisteren, we begeleiden
          </span>
        </p>
      </v-col>
    </v-row>
    <v-row
      class="alternatingContentRow"
    >
      <v-col
        class="center my-8 py-8"
        cols="12"
        lg="8"
      >
        <v-row
          align="center"
          class="my-8 py-8"
        >
          <v-col
            class="center"
            cols="12"
            lg="6"
          >
            <v-img
              class="mr-10"
              :src="require('@/assets/images/Home overlijdensberichten.jpg')"
            />
          </v-col>
          <v-col
            class="center"
            cols="12"
            lg="6"
          >
            <div>
              <h1>
                Afscheid moeten nemen…
              </h1>
              <p>
                Elk leven valt ooit stil.
                Voor de nabestaanden is dat overweldigend en pijnlijk, of verstillend en mooi.
                Het is hoe dan ook een moment dat een mens in zijn diepste wezen raakt.
                Dat beseffen we bij Verstappen uitvaartzorg ten volle.
                Het zet ons aan om elk overlijden met bijzondere zorgen te omringen en de nabestaanden warm en zorgzaam bij te staan.
                Een droeve, maar mooie opdracht.
              </p>
              <p
                class="font-weight-bold"
              >
                Klik hieronder om online te condoleren, bloemen te bestellen of uw aanwezigheid op een koffietafel te bevestigen.
              </p>
              <v-btn
                color="primary"
                tile
                to="Overlijdensberichten"
              >
                Naar overlijdensberichten
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      class="alternatingContentRow"
    >
      <v-col
        class="center my-8 py-8"
        cols="12"
        lg="8"
      >
        <v-row
          class="my-8 py-8"
        >
          <v-col
            class="center"
            cols="12"
            lg="12"
          >
            <h1
              class="text-center"
            >
              De funeraria van Verstappen
            </h1>
          </v-col>
        </v-row>
        <locatie-list
          name="Funeraria"
          @locatie="$router.push(`Funeraria?locatie=${$event}`)"
        />
        <v-row
          class="mb-8 pb-8"
        >
          <v-col
            class="text-center"
            cols="12"
            lg="12"
          >
            <v-btn
              color="primary"
              tile
              to="Contact"
            >
              Maak een afspraak
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      class="alternatingContentRow"
    >
      <v-col
        class="center my-8 py-8"
        cols="12"
        lg="8"
      >
        <v-row
          class="my-8 py-8"
        >
          <v-col
            class="center"
            cols="12"
            lg="12"
          >
            <h1
              class="colorSecondary text-center"
            >
              Uitvaartzorg met een groot hart
            </h1>
          </v-col>
        </v-row>
        <v-row
          class="center"
        >
          <v-col
            class="center"
            cols="12"
            lg="4"
          >
            <h2
              class="text-uppercase"
            >
              We steunen
            </h2>
            <p>
              Het verlies van een dierbare is een van de meest ingrijpende gebeurtenissen in een mensenleven.
              We kunnen het niet ongedaan maken.
              Maar mooi afscheid nemen, kunnen we wel.
              Wij staan u daarin bij, sereen en warm, professioneel en begripvol.
              Zowel praktisch als emotioneel zijn wij uw steun en toeverlaat.
            </p>
          </v-col>
          <v-col
            class="center"
            cols="12"
            lg="4"
          >
            <h2
              class="text-uppercase"
            >
              We luisteren
            </h2>
            <p>
              Zoals geen mens hetzelfde is, is ook elk afscheid uniek.
              We nemen graag de tijd om naar uw specifieke wensen en herinneringen te luisteren tot in de details.
              Samen sluiten we het verhaal van een leven dat voorbijging gepast en waardig af.
            </p>
          </v-col>
          <v-col
            class="center"
            cols="12"
            lg="4"
          >
            <h2
              class="text-uppercase"
            >
              We begeleiden
            </h2>
            <p>
              Een mooie uitvaart is een stevige stapsteen in elk rouwproces.
              Wij begeleiden u daar in alle opzichten naartoe.
              Maar u kan ook op ons rekenen voor wat daarop volgt.
              We leggen graag nog een stukje van de weg af, samen met u.
            </p>
          </v-col>
        </v-row>
        <v-row
          class="mb-8 pb-8"
        >
          <v-col
            class="text-center"
            cols="12"
            lg="12"
          >
            <v-btn
              color="secondary"
              tile
              to="OverOns"
            >
              Meer over Verstappen
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import commonMixin from '@/mixins/commonMixin'
import LocatieList from './LocatieList.vue'

export default {
  components: { LocatieList },
  name: 'HomeComponent',
  mixins: [
    commonMixin
  ],
  mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}
</script>
