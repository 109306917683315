<template>
  <v-container
    v-if="authenticationMixin.checkedLogin"
    class="content pa-0"
    fluid
  >
    <v-form
      v-model="valid"
      ref="form"
    >
      <v-row>
        <v-col
          class="center pt-8"
          cols="12"
          lg="12"
        >
          <v-row
            justify="center"
          >
            <v-col
              class="text-center"
              cols="12"
              lg="8"
            >
              <h2
                class="text-center"
              >
                Condoleances
              </h2>
              <div
                v-if="listMixin.list.length === 0"
              >
                <p>
                  Er zijn momenteel geen condoleances om weer te geven.
                </p>
              </div>
              <div
                v-else
              >
                <v-btn
                  class="my-3"
                  color="secondary"
                  tile
                  @click="Export()"
                >
                  Condoleances afdrukken
                </v-btn>
                <p
                  class="text-center"
                >
                  Hiervoor heeft u Acrobat Reader nodig.
                  U kan dit gratis <a class="secondaryLink" href="https://get.adobe.com/nl/reader/" target="_blank">downloaden</a>.
                </p>
              </div>
            </v-col>
          </v-row>
          <template
            v-for="(item, index) in listMixin.list"
            :key="`${index}-1`"
          >
            <v-row
              class="mt-2 py-8 px-3"
              :class="index % 2 === 1 ? 'alternatingContentRow2' : ''"
              justify="center"
            >
              <v-col
                class="py-2 d-flex align-center"
                cols="12"
                lg="6"
              >
                <div
                  class="flex-grow-1"
                >
                  {{ item.tekst }}
                  <br>
                  <span
                    class="colorPrimary font-weight-bold"
                  >
                    {{ item.naam }}
                  </span>
                </div>
                <v-btn
                  class="ml-3"
                  :disabled="!item.allowReply"
                  icon
                  @click="CondoleanceReply(item)"
                >
                  <v-icon>
                    mdi-reply
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              v-for="(reply, index2) in item.antwoorden"
              class="px-3 pt-0"
              :class="index % 2 === 1 ? 'alternatingContentRow2' : ''"
              justify="center"
              :key="`${index}-2-${index2}`"
            >
              <v-col
                class="py-2 d-flex align-center justify-end"
                cols="12"
                lg="6"
              >
                <div
                  class="flex-grow-1 text-right"
                >
                  {{ reply.tekst }}
                  <br>
                  <span
                    class="colorPrimary font-weight-bold"
                  >
                    {{ reply.naam }}
                  </span>
                </div>
              </v-col>
            </v-row>
            <template
              v-if="reply.item && reply.item.idDossierCondoleance === item.idDossierCondoleance"
            >
              <v-row
                class="px-3 py-0"
                :class="index % 2 === 1 ? 'alternatingContentRow2' : ''"
                justify="center"
                :key="`${index}-3`"
              >
                <v-col
                  class="d-flex align-center pb-0"
                  cols="12"
                  lg="2"
                >
                  <v-text-field
                    v-model="reply.familienaam"
                    base-color="primary"
                    bg-color="white"
                    color="primary"
                    density="compact"
                    label="Uw naam"
                    variant="outlined"
                    :rules="[validationMixin.rules.max50Characters, validationMixin.rules.required]"
                  />
                </v-col>
                <v-col
                  class="d-flex align-center pb-0"
                  cols="12"
                  lg="2"
                >
                  <v-text-field
                    v-model="reply.voornaam"
                    base-color="primary"
                    bg-color="white"
                    color="primary"
                    density="compact"
                    label="Uw voornaam"
                    variant="outlined"
                    :rules="[validationMixin.rules.max50Characters, validationMixin.rules.required]"
                  />
                </v-col>
                <v-col
                  class="d-flex pb-0"
                  cols="12"
                  lg="2"
                  style="margin-top: -6px"
                >
                  <v-btn
                    icon
                    @click="CondoleanceReplySend()"
                  >
                    <v-icon
                    >
                      mdi-send
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                class="px-3 pt-0"
                :class="index % 2 === 1 ? 'alternatingContentRow2' : ''"
                justify="center"
                :key="`${index}-4`"
              >
                <v-col
                  class="d-flex align-center pt-0"
                  cols="12"
                  lg="6"
                >
                  <v-textarea
                    v-model="reply.tekst"
                    auto-grow
                    base-color="primary"
                    bg-color="white"
                    color="primary"
                    density="compact"
                    label="Uw antwoord"
                    variant="outlined"
                    :rules="[validationMixin.rules.required]"
                  />
                </v-col>
              </v-row>
            </template>
          </template>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'
import validationMixin from '@/mixins/validationMixin'
import blobMixin from '@/mixins/blobMixin'
import commonMixin from '@/mixins/commonMixin'
import constantMixin from '@/mixins/constantMixin'
import errorMixin from '@/mixins/errorMixin'
import listMixin from '@/mixins/listMixin'
import klantService from '@/services/klantService'
import rapportService from '@/services/rapportService'

export default {
  name: 'CondoleancesComponent',
  mixins: [
    authenticationMixin,
    blobMixin,
    commonMixin,
    constantMixin,
    errorMixin,
    listMixin,
    validationMixin
  ],
  data () {
    return {
      reply: {
        familienaam: null,
        isNew: true,
        item: null,
        tekst: null,
        voornaam: null
      },
      valid: false
    }
  },
  mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.AuthenticationMixin_CheckIfLoggedIn()
    this.$store.state.headers.raadplegen = true
    klantService.Init(this)
    rapportService.Init(this)
    this.GetUitvaartCondoleanceList()
  },
  beforeUnmount () {
    this.$store.state.headers.raadplegen = false
  },
  methods: {
    async CondoleanceReply (item) {
      this.reply.item = this.reply.item && this.reply.item.idDossierCondoleance === item.idDossierCondoleance ? null : item
      this.reply.familienaam = null
      this.reply.voornaam = null
      this.reply.tekst = null
    },
    async CondoleanceReplySend () {
      this.$refs.form.validate()

      if (this.valid) {
        this.CommonMixin_Load(this.$options.name, true)

        const resp = await klantService.SendUitvaartCondoleanceListAntwoord({
          ...this.reply,
          idDossierCondoleance: this.reply.item.idDossierCondoleance
        })

        if (resp && (resp.data || resp.data !== '')) {
          this.reply.item.antwoorden.push(resp.data)
          await this.CondoleanceReply(this.reply.item)
        }

        this.CommonMixin_Load(this.$options.name, false)
      }
    },
    async Export () {
      this.CommonMixin_Load(this.$options.name, true)
      const resp = await rapportService.Download(this.constantMixin.rapporten.condoleances.id, this.constantMixin.guids.empty)

      if (resp && (resp.data || resp.data !== '')) {
        this.BlobMixin_DownloadApiGeneratedFile(resp)
      }

      this.CommonMixin_Load(this.$options.name, false)
    },
    async GetUitvaartCondoleanceList () {
      this.CommonMixin_Load(this.$options.name, true)
      const resp = await klantService.GetUitvaartCondoleanceList()

      if (resp && (resp.data || resp.data !== '')) {
        this.listMixin.list = resp.data
      }

      this.CommonMixin_Load(this.$options.name, false)
    }
  }
}
</script>
