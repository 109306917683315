export default {
  data () {
    return {
      locationMixin: {
        aulas: {
          naam: 'aula\'s',
          tekst: 'Met onze eigentijdse aula\'s willen we een antwoord bieden op de toenemende vraag naar niet-kerkelijke uitvaarten.<br>Gaat uw voorkeur uit naar een kerkelijke uitvaart, dan kan dit uiteraard ook.<br>Bekijk onze aula\'s van nabij:'
        },
        funeraria: {
          naam: 'funeraria',
          tekst: 'Voor onze zes funeraria legden we de lat hoog. Een eigentijdse, kwaliteitsvolle architectuur, rustgevend groen en waterpartijen waar mogelijk, veel licht en sprekende kunstzinnige accenten… Opdat u in de beste omstandigheden afscheid zou kunnen nemen. Bekijk onze funeraria van nabij:'
        },
        locaties: [
          {
            adres: {
              gemeente: 'Geel',
              huisnummer: '62',
              naam: 'Verstappen uitvaartzorg',
              postcode: '2440',
              straat: 'Diestseweg'
            },
            aulas: {
              gallerij: {
                menuItems: [
                  'Alle'
                ],
                alle: {
                  col1: [1, 2, 3],
                  col2: [4, 5, 6],
                  col3: [7, 8, 9]
                }
              },
              overzicht: [
                '200 zitplaatsen',
                '+ ca. 250 staanplaatsen extra',
                'Audiovisuele technieken',
                'Livestream/opname op aanvraag',
                'Airco'
              ],
              parking: [
                'Eigen parking achteraan met een 50-tal plaatsen',
                'Centrumparking Pas'
              ],
              tekst: [
                'De <b>aula</b> in Geel biedt plaats aan 200 personen. De zij- en achterwanden kunnen weggeschoven worden voor een 250-tal extra staanplaatsen. Er is airco aanwezig en de aula is uitgerust met alle moderne audiovisuele technieken. Op aanvraag kan de dienst worden opgenomen en/of via livestreaming op het internet uitgezonden.',
                'De accommodatie is rolstoelvriendelijk.'
              ],
              type: 'Aula'
            },
            contact: {
              emailadres: 'info@verstappen.be',
              telefoonnummer: '014 58 84 65'
            },
            funeraria: {
              foto: {
                onderschrift: 'Rumo en Christel Verstappen-Daems en hun zoon Michiel: uitvaartzorg in Geel.'
              },
              gallerij: {
                menuItems: [
                  'Alle',
                  'Funerarium',
                  'Aula',
                  'Winkel',
                  'Buiten'
                ],
                alle: {
                  col1: [1, 2, 3, 4, 5, 6],
                  col2: [7, 8, 9, 10, 11, 12],
                  col3: [13, 14, 15, 16, 17]
                },
                aula: {
                  col1: [3],
                  col2: [9],
                  col3: []
                },
                buiten: {
                  col1: [2, 7],
                  col2: [10],
                  col3: [12]
                },
                funerarium: {
                  col1: [1, 5],
                  col2: [8],
                  col3: [14]
                },
                winkel: {
                  col1: [4, 6, 11],
                  col2: [13, 15],
                  col3: [16, 17]
                }
              },
              openingsuren: [
                'Funerarium open na afspraak',
                'Winkel open van maandag t.e.m. vrijdag: 14-17 uur'
              ],
              overzicht: [
                '2 familiekamers',
                '2 groetkamers',
                'Koffieruimte tot 145 personen',
                'Winkel',
                'Aula'
              ],
              parking: [
                'Eigen parking achteraan',
                'Centrumparking Pas'
              ],
              tekst: [
                'In Geel beschikt het <b>funerarium</b> over vier groetkamers met bijhorende familiekamers. Daar kan u in alle privacy samenkomen om de overledene te gedenken en steun te vinden bij mekaar. Er is parking voor een 50-tal wagens terwijl de centrumparking Pas op wandelafstand ligt.',
                'Voor de uitvaartdienst is er een <b>aula</b> die plaats biedt aan 200 personen. De zijwanden kunnen weggeschoven worden voor een 150-tal extra staanplaatsen. De aula is voorzien van airco en is uitgerust voor alle moderne audiovisuele technieken. Op aanvraag kan de dienst worden gefilmd, opgenomen en/of via livestreaming op het internet uitgezonden.',
                'U kunt hier eveneens gebruik maken van onze <b>koffieruimte</b> voor max. 145 personen. Voor minder grote gezelschappen is deze ruimte opdeelbaar in twee kleinere, aparte segmenten.',
                'Onze <b>winkel</b> is elke weekdag open van 14 tot 17 uur, en op afspraak. We helpen u graag bij de keuze van een urne of kist, een bloemstuk of een gedachtenisjuweeltje. In onze winkel vindt u een ruim aanbod naar ieders smaak. Het aanbod bevat zowel natuurlijke als zijden bloemen. Er is ook een ruime keuze in grafmonumenten (monumentjes voor op het urneveld, columbariumplaten, gedenksteentjes, foto\'s...) en herinneringssierraden met as en/of een vingerafdruk.',
                'We ontvangen u in alle comfort en discretie in ons <b>uitvaartkantoor</b> in Geel om alles in verband met een uitvaart te bespreken. Ook voor een gesprek over hoe ú ooit uw uitvaart ziet of voor informatie over een uitvaartverzekering kunt u hier terecht.',
                'Al onze accommodaties zijn rolstoelvriendelijk.'
              ],
              type: 'Funerarium'
            },
            locatie: 'Geel'
          },
          {
            adres: {
              gemeente: 'Kasterlee',
              huisnummer: '53',
              naam: 'Verstappen uitvaartzorg',
              postcode: '2460',
              straat: 'Turnhoutsebaan'
            },
            contact: {
              emailadres: 'info@verstappen.be',
              telefoonnummer: '014 85 17 96'
            },
            funeraria: {
              foto: {
                onderschrift: 'In Kasterlee en Lichtaart is uw uitvaartzorg in goede handen bij Ingrid en Tim Wuyts.'
              },
              gallerij: {
                menuItems: [
                  'Alle',
                  'Funerarium',
                  'Winkel',
                  'Buiten'
                ],
                alle: {
                  col1: [1, 2, 3, 4, 5, 6],
                  col2: [7, 8, 9, 10, 11, 12],
                  col3: [13, 14, 15, 16, 17]
                },
                buiten: {
                  col1: [7],
                  col2: [14],
                  col3: []
                },
                funerarium: {
                  col1: [4, 8, 5],
                  col2: [13, 12],
                  col3: [16, 15]
                },
                winkel: {
                  col1: [1, 2, 3],
                  col2: [9, 6, 11],
                  col3: [10, 17]
                }
              },
              openingsuren: [
                'Funerarium en winkel open na afspraak'
              ],
              overzicht: [
                '2 groetkamers',
                '2 familiekamers',
                'Winkel'
              ],
              parking: [
                'Parkeergelegenheid langs de weg'
              ],
              tekst: [
                'Het <b>funerarium</b> telt twee groetkamers met bijhorende familieruimtes om in alle privacy samen te komen, de overledene te gedenken en steun te vinden bij mekaar.',
                'In onze <b>winkel</b> helpen we u graag bij de keuze van een urne of kist, een bloemstuk of een gedachtenisjuweeltje. Het aanbod bevat zowel natuurlijke als zijden bloemen. Er is ook een ruime keuze in grafmonumenten (monumentjes voor op het urneveld, columbariumplaten, gedenksteentjes, foto\'s...) en herinneringssierraden met as en/of een vingerafdruk.',
                'Funerarium en winkel zijn open na afspraak. De accommodatie in Kasterlee is rolstoelvriendelijk.'
              ],
              type: 'Funerarium'
            },
            locatie: 'Kasterlee'
          },
          {
            adres: {
              gemeente: 'Lichtaart',
              huisnummer: '40',
              naam: 'Verstappen uitvaartzorg',
              postcode: '2460',
              straat: 'Leistraat'
            },
            aulas: {
              gallerij: {
                menuItems: [
                  'Alle'
                ],
                alle: {
                  col1: [1, 2, 3],
                  col2: [4, 5, 6],
                  col3: [7, 8, 9]
                }
              },
              overzicht: [
                '175 zitplaatsen',
                '+ ca. 150 staanplaatsen extra',
                'Audiovisuele technieken',
                'Livestream/opname op aanvraag',
                'Airco'
              ],
              parking: [
                '50-tal plaatsen aan de kerk'
              ],
              tekst: [
                'De aula in Lichtaart biedt plaats aan 175 mensen maar kan met open wanden worden uitgebreid met nog een 150-tal extra staanplaatsen. Er is airco voorzien en de ruimte is uitgerust voor alle moderne audiovisuele technieken. Op aanvraag kan de dienst worden gefilmd, opgenomen en/of via livestreaming op het internet uitgezonden.',
                'De accommodatie in Lichtaart is rolstoelvriendelijk.'
              ],
              type: 'Aula'
            },
            contact: {
              emailadres: 'info@verstappen.be',
              telefoonnummer: '014 45 34 00'
            },
            funeraria: {
              foto: {
                onderschrift: 'In Kasterlee en Lichtaart is uw uitvaartzorg in goede handen bij Ingrid en Tim Wuyts.'
              },
              gallerij: {
                menuItems: [
                  'Alle',
                  'Funerarium',
                  'Aula',
                  'Winkel',
                  'Buiten'
                ],
                alle: {
                  col1: [1, 2, 3, 4, 5, 6],
                  col2: [7, 8, 9, 10, 11, 12],
                  col3: [13, 14, 15, 16, 17]
                },
                aula: {
                  col1: [2, 4],
                  col2: [15],
                  col3: []
                },
                buiten: {
                  col1: [1, 5],
                  col2: [6],
                  col3: [9]
                },
                funerarium: {
                  col1: [3, 7],
                  col2: [11, 14],
                  col3: [13]
                },
                winkel: {
                  col1: [8, 12],
                  col2: [16, 10],
                  col3: [17]
                }
              },
              openingsuren: [
                'Funerarium en winkel open na afspraak'
              ],
              overzicht: [
                '2 groetkamers',
                'Winkel',
                'Aula'
              ],
              parking: [
                '50-tal plaatsen aan de kerk'
              ],
              tekst: [
                'Het <b>funerarium</b> telt 2 groetkamers met lounge om in alle privacy samen te komen, de overledene te gedenken en steun te vinden bij mekaar. Vlakbij, aan de kerk is plaats voor een 50-tal wagens.',
                'De <b>aula</b> biedt plaats aan 175 mensen maar kan met open wanden worden uitgebreid met nog een 150-tal extra staanplaatsen. Hij is voorzien van airco en is uitgerust voor alle moderne audiovisuele technieken. Op aanvraag kan de dienst worden gefilmd, opgenomen en/of via livestreaming op het internet uitgezonden.',
                'In onze <b>winkel</b> helpen we u graag bij de keuze van een urne of kist, een bloemstuk of een gedachtenisjuweeltje. Het aanbod bevat zowel natuurlijke als zijden bloemen. Er is ook een ruime keuze in grafmonumenten (monumentjes voor op het urneveld, columbariumplaten, gedenksteentjes, foto\'s...) en herinneringssierraden met as en/of een vingerafdruk.',
                'Funerarium en winkel zijn open na afspraak. De accommodatie in Lichtaart is rolstoelvriendelijk.'
              ],
              type: 'Funerarium'
            },
            locatie: 'Lichtaart'
          },
          {
            adres: {
              gemeente: 'Beerse',
              huisnummer: '5',
              naam: 'Verstappen uitvaartzorg',
              postcode: '2340',
              straat: 'Abdijstraat'
            },
            contact: {
              emailadres: 'info@verstappen.be',
              telefoonnummer: '014 61 66 02'
            },
            funeraria: {
              foto: {
                onderschrift: 'Steven en Lieve Verstappen staan voor u klaar in Beerse, Merksplas en Turnhout.'
              },
              gallerij: {
                menuItems: [
                  'Alle',
                  'Funerarium',
                  'Winkel',
                  'Buiten'
                ],
                alle: {
                  col1: [1, 2, 3, 4, 5, 6],
                  col2: [7, 8, 9, 10, 11, 12],
                  col3: [13, 14, 15, 16, 17]
                },
                buiten: {
                  col1: [6, 1],
                  col2: [16, 8],
                  col3: [10]
                },
                funerarium: {
                  col1: [5, 13, 7],
                  col2: [15, 9],
                  col3: [12, 17]
                },
                winkel: {
                  col1: [2, 4],
                  col2: [3],
                  col3: [11, 14]
                }
              },
              openingsuren: [
                'Funerarium open na afspraak',
                'Winkel open dinsdag en donderdag: 18-19 uur',
                'zaterdag: 10-12 uur en na afspraak'
              ],
              overzicht: [
                '2 groetkamers',
                '2 familiekamers',
                'Winkel'
              ],
              parking: [
                'Parkeergelegenheid vooraan'
              ],
              tekst: [
                'Het <b>funerarium</b> telt 2 groetkamers met familiekamers om in alle privacy samen te komen, de overledene te gedenken en steun te vinden bij mekaar. Er is parkeerplaats voor 11 wagens. Het funerarium is open na afspraak.',
                'In onze <b>winkel</b> helpen we u graag bij de keuze van een urne of kist, een bloemstuk of een gedachtenisjuweeltje. Het aanbod bevat zowel natuurlijke als zijden bloemen. Er is ook een ruime keuze in grafmonumenten (monumentjes voor op het urneveld, columbariumplaten, gedenksteentjes, foto\'s...) en herinneringssierraden met as en/of een vingerafdruk.<br>De winkel is open op dinsdag en donderdag van 18 tot 19 uur,<br>op zaterdag van 10 tot 12 uur en op afspraak.',
                'Voor onze diensten in Beerse maken we gebruik van de <a class="secondaryLink"><u>aula van het crematorium in Turnhout</u></a>.',
                'Alle accommodatie is rolstoelvriendelijk.'
              ],
              type: 'Funerarium'
            },
            locatie: 'Beerse'
          },
          {
            adres: {
              gemeente: 'Merksplas',
              huisnummer: '1A',
              naam: 'Verstappen uitvaartzorg',
              postcode: '2330',
              straat: 'Kerkplein'
            },
            contact: {
              emailadres: 'info@verstappen.be',
              telefoonnummer: '014 63 32 14'
            },
            funeraria: {
              foto: {
                onderschrift: 'Steven en Lieve Verstappen staan voor u klaar in Beerse, Merksplas en Turnhout.'
              },
              gallerij: {
                menuItems: [
                  'Alle',
                  'Funerarium',
                  'Winkel',
                  'Buiten'
                ],
                alle: {
                  col1: [1, 2, 3, 4, 5, 6],
                  col2: [7, 8, 9, 10, 11, 12],
                  col3: [13, 14, 15, 16, 17]
                },
                buiten: {
                  col1: [1, 16],
                  col2: [8],
                  col3: []
                },
                funerarium: {
                  col1: [4, 5, 7],
                  col2: [15],
                  col3: [12, 14]
                },
                winkel: {
                  col1: [3, 6, 2],
                  col2: [9, 10, 11],
                  col3: [13, 17]
                }
              },
              openingsuren: [
                'Funerarium en winkel open na afspraak'
              ],
              overzicht: [
                '2 groetkamers',
                '1 familiekamer',
                'Winkel'
              ],
              parking: [
                'Parkeergelegenheid naastgelegen kerkplein'
              ],
              tekst: [
                'Het <b>funerarium</b> telt 2 groetkamers met lounge om in alle privacy samen te komen, de overledene te gedenken en steun te vinden bij mekaar.',
                'In onze <b>winkel</b> helpen we u graag bij de keuze van een urne of kist, een bloemstuk of een gedachtenisjuweeltje. Het aanbod bevat zowel natuurlijke als zijden bloemen. Er is ook een ruime keuze in grafmonumenten (monumentjes voor op het urneveld, columbariumplaten, gedenksteentjes, foto\'s...) en herinneringssierraden met as en/of een vingerafdruk.',
                'Funerarium en winkel zijn open na afspraak. Alle accommodaties zijn rolstoelvriendelijk.'
              ],
              type: 'Funerarium'
            },
            locatie: 'Merksplas'
          },
          {
            adres: {
              gemeente: 'Turnhout',
              huisnummer: '212',
              naam: 'Verstappen uitvaartzorg',
              postcode: '2300',
              straat: 'Otterstraat'
            },
            aulas: {
              gallerij: {
                menuItems: [
                  'Alle'
                ],
                alle: {
                  col1: [1, 2, 3],
                  col2: [4, 5, 7],
                  col3: [6, 8, 9]
                }
              },
              overzicht: [
                '200 zitplaatsen',
                '+ ca. 250 staanplaatsen extra',
                'Audiovisuele technieken',
                'Livestream/opname op aanvraag',
                'Airco'
              ],
              parking: [
                'Eigen parking achteraan met een 50-tal plaatsen',
                'Centrumparking Pas'
              ],
              tekst: [
                'Via de rustgevende binnentuin betreed je de aula. De uitvaartaula in Turnhout biedt plaats aan 50 personen en is uitgerust met een groot TV-scherm waardoor fotopresentaties volledig tot hun recht komen. De airco zorgt voor een comfortabel binnenklimaat. Het uitvaartcentrum is rolstoelvriendelijk.',
                'Op 250m van Verstappen uitvaartzorg Turnhout bevindt zich de centrumparking Boomgaard. De parking is eveneens voorzien van een oplaadpunt voor elektrische wagens.'
              ],
              type: 'Aula'
            },
            contact: {
              emailadres: 'info@verstappen.be',
              telefoonnummer: '014 41 13 47'
            },
            funeraria: {
              gallerij: {
                menuItems: [
                  'Alle',
                  'Funerarium',
                  'Aula',
                  'Winkel',
                  'Buiten'
                ],
                alle: {
                  col1: [2, 1, 5, 3, 6, 7, 8],
                  col2: [11, 9, 12, 10, 13, 15, 14, 18],
                  col3: [16, 20, 17, 21, 19, 22, 23]
                },
                aula: {
                  col1: [2],
                  col2: [1],
                  col3: []
                },
                buiten: {
                  col1: [5, 8],
                  col2: [3, 4, 7, 10],
                  col3: [6, 9]
                },
                funerarium: {
                  col1: [11, 15],
                  col2: [13, 14],
                  col3: [12, 16]
                },
                winkel: {
                  col1: [17, 18],
                  col2: [20, 21, 19],
                  col3: [23, 22]
                }
              },
              foto: {
                onderschrift: 'Steven en Lieve Verstappen staan voor u klaar in Beerse, Merksplas en Turnhout.'
              },
              openingsuren: [
                'Funerarium open na afspraak',
                'Winkel open maandag, woensdag en vrijdag: 17-18 uur en na afspraak'
              ],
              overzicht: [
                '2 familiekamers',
                '2 groetkamers',
                'Winkel',
                'Aula'
              ],
              parking: [
                'Parking Boomgaardstraat'
              ],
              tekst: [
                'Het <b>funerarium</b> in Turnhout beschikt over twee groetkamers met elk een bijhorende familiekamer. Daar kan u in alle privacy samenkomen om de overledene te gedenken en steun te vinden bij mekaar. Op wandelafstand van het funerarium (250m) bevindt zich de centrumparking Boomgaard. Daar is ook een laadpunt voor elektrische wagens.',
                'We beschikken over een <b>uitvaartaula</b> die plaats biedt aan 50 personen voor een afscheid in intieme kring. De airco zorgt voor een comfortabel binnenklimaat. De uitvaartaula is uitgerust met een groot scherm waardoor fotopresentaties volledig tot hun recht komen.',
                'De aula is toegankelijk via de ruime en rustgevende <b>binnentuin</b>. Accenten in cortenstaal, groene bloemen- en plantenzones, kunstzinnige zitobjecten en een waterpartij zijn de blikvangers.',
                'Onze <b>winkel</b> is open op maandag, woensdag en vrijdag van 17 tot 18 uur, en op afspraak. We helpen u graag bij de keuze van een urne of kist, een bloemstuk of een herinneringsjuweeltje. Het aanbod bloemen bevat zowel natuurlijke als zijden bloemen. Er is ook een ruime keuze in grafmonumenten (monumentjes voor op het urneveld, columbariumplaten, gedenksteentjes, foto\'s…) en herinneringssierraden met as en/of een vingerafdruk.',
                'Al onze accommodaties zijn rolstoelvriendelijk.'
              ],
              type: 'Funerarium'
            },
            locatie: 'Turnhout'
          }
        ]
      }
    }
  }
}
