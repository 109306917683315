import formatMixin from '@/mixins/formatMixin'
import textMixin from '@/mixins/textMixin'
import _ from 'lodash'

export default {
  mixins: [
    formatMixin,
    textMixin
  ],
  data () {
    return {
      commonMixin: {
        loggedIn: false,
        snackbar: {
          color: '',
          show: false,
          text: '',
          timeout: -1
        }
      }
    }
  },
  computed: {
    CommonMixin_Disable () {
      // return this.CommonMixin_Error || this.CommonMixin_Loading
      return this.CommonMixin_Loading
    },
    CommonMixin_Error () {
      return this.$store.state.error
    },
    CommonMixin_Loading () {
      return this.$store.state.loading && this.$store.state.loading.length > 0
    },
    CommonMixin_Theme () {
      if (this.$vuetify.theme.isDark) {
        return this.$vuetify.theme.themes.dark
      } else {
        return this.$vuetify.theme.themes.light
      }
    }
  },
  methods: {
    CommonMixin_ArrayRemoveElement (array, element) {
      const index = array.indexOf(element)

      if (index !== -1) {
        array.splice(index, 1)
      }
    },
    CommonMixin_Clone (source) {
      let clone = _.clone(source)

      if (source != null) {
        if (Array.isArray(source)) {
          clone = []
          for (let index = 0; index < source.length; index++) {
            clone[index] = this.CommonMixin_Clone(source[index])
          }
        } else if (typeof (source) === 'object') {
          const keys = (Object.keys(source))

          for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            clone[key] = this.CommonMixin_Clone(source[key])
          }
        }
      }

      return clone
    },
    CommonMixin_Confirmation (condition, text, defaultValue) {
      let confirmation = defaultValue

      if (condition) {
        confirmation = window.confirm(text)
      }

      return confirmation
    },
    CommonMixin_DeepCopy (object) {
      return JSON.parse(JSON.stringify(object))
    },
    CommonMixin_GetDate () {
      const today = new Date()
      const date = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
      return this.FormatMixin_FormatDateTime(date, this.formatMixin.date.form, this.formatMixin.date.database)
    },
    CommonMixin_GetElementByPropertyValue (list, property, value) {
      if (list == null) {
        return {}
      } else {
        return list.find(element => element[property] === value) ?? {}
      }
    },
    CommonMixin_GetElementsByPropertyValue (list, property, value) {
      if (list == null) {
        return []
      } else {
        return list.filter(element => element[property] === value) ?? []
      }
    },
    CommonMixin_GetPageCount (list, numberPerPage) {
      const minimum = Math.floor(list.length / numberPerPage)
      const extra = list.length % numberPerPage > 0
      const total = minimum + (extra ? 1 : 0)

      return total > 0 ? total : 1
    },
    CommonMixin_GetPageList (list, page, numberPerPage) {
      const end = page * numberPerPage > list.length ? list.length : page * numberPerPage
      return list.slice((page - 1) * numberPerPage, end)
    },
    CommonMixin_HardCompare (source, target) {
      return _.isEqual(source, target)
    },
    CommonMixin_HardCopy (object, source) {
      if (source != null) {
        if (Array.isArray(source)) {
          object = []
          for (let index = 0; index < source.length; index++) {
            object[index] = this.CommonMixin_Clone(source[index])
          }
        } else if (typeof (source) === 'object') {
          const keys = (Object.keys(source))

          for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            object[key] = this.CommonMixin_Clone(source[key])
          }
        } else {
          object = _.clone(source)
        }
      }

      return object
    },
    CommonMixin_Load (component, loading) {
      const object = {
        component: component,
        loading: loading
      }

      this.$store.dispatch('showLoader', object)
    },
    CommonMixin_SelectedStyle (selected) {
      const backgroundColor = this.CommonMixin_Theme.primary ? this.CommonMixin_Theme.primary : 'gray'

      if (selected) {
        return {
          'background-color': backgroundColor,
          color: 'white'
        }
      }
    },
    CommonMixin_SetVCardHeights (elementId1, elementId2) {
      const element1 = document.getElementById(elementId1)
      const element2 = document.getElementById(elementId2)

      if (element1 && element2) {
        const rectangle1 = element1.getBoundingClientRect()
        const rectangle2 = element2.getBoundingClientRect()

        if (rectangle1.left !== rectangle2.left) {
          if (rectangle1.bottom > rectangle2.bottom) {
            element2.style.height = `${rectangle1.bottom - rectangle2.top}px`
          } else if (rectangle2.bottom > rectangle1.bottom) {
            element1.style.height = `${rectangle2.bottom - rectangle1.top}px`
          }
        }
      }
    },
    CommonMixin_Snackbar (text, color, timeout = 10) {
      const snackbar = {
        color: color,
        show: true,
        text: text,
        timeout: timeout
      }

      this.$store.dispatch('showSnackbar', snackbar)
    },
    CommonMixin_SuccessOrFailMessage (success, successMessage, failMessage) {
      if (success) {
        if (successMessage) {
          this.CommonMixin_Snackbar(successMessage, 'success')
        }
      } else {
        if (failMessage) {
          this.CommonMixin_Snackbar(failMessage, 'error')
        }
      }
    }
  }
}
