<template>
  <logout />
</template>

<script>
import Logout from '../components/Logout.vue'

export default {
  name: 'LogoutView',
  components: {
    Logout
  }
}
</script>
