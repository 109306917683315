<template>
  <v-row>
    <v-col
      cols="12"
      lg="6"
    >
      <control-select
        v-model="duplicate.maand"
        itemValue="value"
        itemText="name"
        :items="maanden"
        label="Maand"
        @change="Emit('maand', $event)"
      />
    </v-col>
    <v-col
      cols="12"
      lg="6"
    >
      <control-number
        v-model="duplicate.jaar"
        :invalidNumberText="invalidNumberText"
        label="Jaar"
        :min="1950"
        naturalNumber
        :reverse="false"
        @change="Emit('jaar', $event)"
      />
    </v-col>
  </v-row>
</template>
<script>
import ControlNumber from './ControlNumber.vue'
import ControlSelect from './ControlSelect.vue'

export default {
  name: 'FilterPeriode',
  components: {
    ControlNumber,
    ControlSelect
  },
  props: {
    initialize: Boolean,
    invalidNumberText: {
      default: 'Invalid number.',
      type: String
    },
    jaar: [Number, String],
    maand: [Number, String]
  },
  data () {
    return {
      duplicate: {
        jaar: null,
        maand: null
      },
      maanden: [
        {
          name: 'Januari',
          value: 1
        },
        {
          name: 'Februari',
          value: 2
        },
        {
          name: 'Maart',
          value: 3
        },
        {
          name: 'April',
          value: 4
        },
        {
          name: 'Mei',
          value: 5
        },
        {
          name: 'Juni',
          value: 6
        },
        {
          name: 'Juli',
          value: 7
        },
        {
          name: 'Augustus',
          value: 8
        },
        {
          name: 'September',
          value: 9
        },
        {
          name: 'Oktober',
          value: 10
        },
        {
          name: 'November',
          value: 11
        },
        {
          name: 'December',
          value: 12
        }
      ]
    }
  },
  mounted () {
    if (this.initialize) {
      this.duplicate.jaar = new Date().getFullYear()
      this.duplicate.maand = new Date().getMonth() + 1
      this.$emit('jaar', this.duplicate.jaar)
      this.$emit('maand', this.duplicate.maand)
      this.$emit('change')
    }
  },
  watch: {
    jaar () {
      this.duplicate.jaar = this.jaar
    },
    maand () {
      this.duplicate.maand = this.maand
    }
  },
  methods: {
    Emit (event, value) {
      this.$emit(event, value)
      this.$emit('change')
    }
  }
}
</script>
