<template>
  <extra-diensten />
</template>

<script>
import ExtraDiensten from '../components/ExtraDiensten'

export default {
  name: 'ExtraDienstenView',
  components: {
    ExtraDiensten
  }
}
</script>
