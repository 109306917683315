<template>
  <v-container
    v-if="authenticationMixin.checkedLogin"
    class="content pa-0"
    fluid
  >
    <v-row
      class="alternatingContentRow pa-0"
    >
    </v-row>
    <v-row
      class="alternatingContentRow my-8 py-8"
    >
      <v-col
        class="center"
        cols="12"
        lg="10"
      >
        <h2
          class="colorPrimary text-center"
        >
          Hoofding rouwbrief
        </h2>
        <p
          class="text-center"
        >
          U kan een gedichtje kiezen of zich laten inspireren.
        </p>
      </v-col>
      <v-col
        class="center mt-5"
        cols="12"
        lg="6"
      >
        <v-row>
          <v-col
            v-for="(item, index) in data.hoofdingen"
            :key="index"
            class="px-10 pt-5"
            :class="item.idRouwbriefhoofding === data.idRouwbriefhoofding ? 'elementSelected' : ''"
            cols="12"
            lg="6"
          >
            <v-row>
              <v-col
                cols="12"
                lg="12"
              >
                <span
                  class="colorPrimary volgnummer"
                >
                  {{item.volgnummer}}
                </span>
                <p
                  style="white-space: pre-line"
                >
                  {{ item.tekst }}
                  <br>
                  <span
                    class="font-italic"
                  >
                    {{ item.auteur }}
                  </span>
                </p>
              </v-col>
              <v-col
                cols="6"
                lg="8"
              >
                <div
                  style="border-bottom: 1px solid #AAAAAA; margin-top: 35px;"
                />
              </v-col>
              <v-col
                cols="6"
                lg="4"
              >
                <v-btn
                  block
                  color="primary"
                  :disabled="item.idRouwbriefhoofding === data.idRouwbriefhoofding"
                  tile
                  @click="Selecteer(item)"
                  >
                  Kies
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="mt-3"
            cols="12"
            lg="12"
          >
            <v-textarea
              v-model="data.rouwbriefhoofdingOpmerking"
              auto-grow
              base-color="primary"
              bg-color="white"
              color="primary"
              density="compact"
              label="Opmerking"
              variant="outlined"
            />
            <v-btn
              color="primary"
              tile
              @click="Selecteer(data)"
              >
              Opslaan
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'
import errorMixin from '@/mixins/errorMixin'
import klantService from '@/services/klantService'

export default {
  name: 'RouwdrukwerkComponent',
  mixins: [
    authenticationMixin,
    errorMixin,
  ],
  data () {
    return {
      data: {
        hoofdingen: []
      }
    }
  },
  async mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.AuthenticationMixin_CheckIfLoggedIn()
    klantService.Init(this)
    this.$store.state.headers.samenstellen = true
    await this.GetRouwbriefhoofdingList()
  },
  beforeUnmount () {
    this.$store.state.headers.samenstellen = false
  },
  methods: {
    async GetRouwbriefhoofdingList () {
      this.CommonMixin_Load(this.$options.name, true)
      const resp = await klantService.GetUitvaartRouwbriefhoofdingList()

      if (resp && (resp.data || resp.data !== '')) {
        this.data = this.CommonMixin_Clone(resp.data)
      }

      this.CommonMixin_Load(this.$options.name, false)
    },
    async Selecteer (rouwbriefhoofding) {
      this.CommonMixin_Load(this.$options.name, true)
      this.data.idRouwbriefhoofding = rouwbriefhoofding.idRouwbriefhoofding
      const resp = await klantService.SaveUitvaartRouwbriefhoofding(this.data)

      if (resp && (resp.data || resp.data !== '')) {
        await this.GetRouwbriefhoofdingList()
      }

      this.CommonMixin_Load(this.$options.name, false)
    }
  }
}
</script>
