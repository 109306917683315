<template>
  <div>
    <div
      class="d-flex"
    >
      <v-icon
        color="black"
        style="cursor: pointer;"
        @click="Play()"
      >
        mdi-{{ playing ? 'pause' : 'play'}}
      </v-icon>
      <v-progress-linear
        v-model="progressTrack"
        class="mx-3"
        :height="height"
        style="cursor: pointer; margin-top: 9px;"
        @change="UpdateSeek($event)"
      />
      <span
        style="font-size: 14px"
      >
        {{ DecimalToDuration(seek) }}&nbsp;/&nbsp;{{ DecimalToDuration(howl ? howl.duration() : null) }}
      </span>
    </div>
    <div
      class="d-flex"
    >
      <span
        class="center"
        style="font-size: 14px"
      >
        {{ title }}
      </span>
    </div>
  </div>
</template>

<script>
import { Howl } from 'howler'

export default {
  name: 'ControlMusic',
  props: {
    artist: String,
    height: {
      default: 5,
      type: Number
    },
    title: String,
    modelValue: String
  },
  data () {
    return {
      duration: 0,
      howl: null,
      playing: false,
      progressTrack: 0,
      seek: 0,
      updateSeek: null
    }
  },
  beforeUnmount () {
    clearInterval(this.updateSeek)

    if (this.howl) {
      this.howl.stop()
    }
  },
  mounted () {
    this.Initialize()
  },
  watch: {
    playing (modelValue) {
      this.seek = this.howl.seek()

      if (modelValue) {
        this.updateSeek = setInterval(() => {
          this.seek = this.howl.seek()
          this.duration = this.howl.duration()
        }, 250)
      } else {
        this.duration = 0
        clearInterval(this.updateSeek)
      }
    },
    Progress () {
      this.progressTrack = this.Progress * 100
    },
    modelValue () {
      this.Initialize()
    }
  },
  computed: {
    Progress () {
      return this.duration !== 0 ? this.seek / this.duration : 0
    }
  },
  methods: {
    Initialize () {
      if (this.howl) {
        this.playing = false
        this.howl.stop()
      }

      this.progressTrack = 0
      this.seek = 0
      this.howl = this.modelValue ? new Howl({
        loop: true,
        src: [this.modelValue]
      }) : null

      if (this.howl) {
        this.playing = true
        this.howl.play()
      }
    },
    DecimalToDuration (decimal) {
      var sign = decimal < 0 ? '-' : ''
      var min = Math.floor(Math.abs(decimal) / 60)
      var sec = Math.floor(decimal % 60)
      return `${sign}${min}:${(sec < 10 ? '0' : '')}${sec}`
    },
    Play () {
      if (this.howl) {
        if (this.playing) {
          this.howl.pause()
        } else {
          this.howl.play()
        }

        this.playing = !this.playing
      } else {
        this.$emit('noSource')
      }
    },
    UpdateSeek (event) {
      if (this.howl && this.howl.playing()) {
        this.howl.seek((this.howl.duration() / 100) * event)
      }
    }
  }
}
</script>
