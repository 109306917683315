<template>
  <administratie />
</template>

<script>
import Administratie from '../components/Administratie.vue'

export default {
  name: 'AdministratieView',
  components: {
    Administratie
  }
}
</script>
