<template>
  <v-container
    class="content pa-0"
    fluid
  >
    <v-row
      class="alternatingContentRow pa-0"
    >
      <v-col
        cols="12"
        lg="12"
      >
        <v-img
          :src="require(`@/assets/images/BereidJeUitvaartVoor banner.jpg`)"
        />
      </v-col>
    </v-row>
    <v-row
      class="alternatingContentRow"
    >
      <v-col
        class="center my-8 py-8"
        cols="12"
        lg="8"
      >
        <h1
          class="text-center"
        >
          <a
            class="noUnderline"
            title="Terug"
            @click="$router.push('ExtraDiensten')"
          >
            <v-icon
              class="iconBack iconPrimaryColor"
            >
              mdi-arrow-left-circle
            </v-icon>
          </a>
          Zo wil ik straks afscheid nemen
        </h1>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            lg="6"
          >
            <p>
              Uitvaartzorg is een warme vorm van zorgen voor mekaar.
              Het is echter ook: mekaar ontzorgen.
              Na een overlijden rijzen er heel wat vragen voor de nabestaanden en worden zij voor talrijke keuzes gesteld.
              Hoe zou de overledene zijn afscheid gezien willen hebben?
              En hoever kunnen we gaan met de kosten?
            </p>
            <p>
              Om hun nabestaanden op dat vlak te ontzorgen, kiezen meer en meer mensen - van elke leeftijd! - ervoor om hun wensen in verband met hun afscheid vooraf vast te leggen.
              Dat schept duidelijkheid en gemoedsrust voor iedereen.
            </p>
            <p>
              Ook u kan uw uitvaart samen met ons tot in detail voorbereiden.
              Alle persoonlijke wensen leggen we vast in een uitvaartwensenmapje.
              En wie financiële regelingen wil treffen voor zijn/haar uitvaart, kan via ons een <a @click="$router.push('Uitvaartverzekering')">uitvaartverzekering</a> nemen.
            </p>
            <p>
              Wenst u meer info of een vrijblijvend gesprek?
              Neem vandaag nog contact met ons op!
            </p>
            <v-btn
              color="primary"
              tile
              to="Contact"
            >
              Neem contact op
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            lg="3"
          >
            <h2
              class="pb-0 text-uppercase"
            >
              Plan een voorgesprek
            </h2>
            <p>
              Verstappen uitvaartzorg
              <br>
              Diestseweg 62
              <br>
              2440 Geel
            </p>
            <p>
              014 58 84 65
              <br>
              <a
                href="mailto:info@verstappen.be"
              >
                info@verstappen.be
              </a>
            </p>
            <v-btn
              color="primary"
              tile
              to="Contact"
            >
              Maak een afspraak
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'BereidJeUitvaartVoor',
  mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}
</script>
