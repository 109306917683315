<template>
  <v-container
    class="footer"
    color="transparent"
    fluid
  >
    <v-btn
      class="float-right btnGoToTop pa-0"
      color="secondary"
      fab
      size="x-small"
      tile
      @click="ScrollToTop()"
    >
      <v-icon
        color="white"
        size="10"
      >
        mdi-triangle
      </v-icon>
    </v-btn>
    <v-container
      class="hidden-sm-and-down menu my-5 py-5"
    >
      <v-row
        justify="center"
      >
        <v-col
          cols="12"
          lg="2"
        >
          <p>
            <a
              class="text-uppercase"
              @click="$router.push('/')"
            >
              Startpagina
            </a>
          </p>
          <p>
            <a
              class="text-uppercase"
              @click="$router.push('OverOns')"
            >
              Over ons
            </a>
          </p>
          <p>
            <a
              class="text-uppercase"
              @click="$router.push('Nieuws')"
            >
              Nieuws
            </a>
          </p>
          <!-- <p>
            <a
              class="text-uppercase"
              @click="$router.push('VeelgesteldeVragen')"
            >
              Veelgestelde vragen
            </a>
          </p> -->
          <p>
            <a
              class="text-uppercase"
              @click="$router.push('Contact')"
            >
              Contact
            </a>
          </p>
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <p>
            <a
              class="text-uppercase"
              @click="$router.push('Overlijdensberichten')"
            >
              Overlijdensberichten
            </a>
          </p>
          <p
            class="subItem"
          >
            <a
              @click="$router.push('Overlijdensberichten?onderdeel=Condoleren')"
            >
              Online condoleren
            </a>
          </p>
          <p
            class="subItem"
          >
            <a
              @click="$router.push('Overlijdensberichten?onderdeel=Bloemen')"
            >
              Bloemen bestellen
            </a>
          </p>
          <p
            class="subItem"
          >
            <a
              @click="$router.push('Overlijdensberichten?onderdeel=Koffietafel')"
            >
              Koffietafel bevestigen
            </a>
          </p>
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <p>
            <a
              class="text-uppercase"
              @click="$router.push('Funeraria')"
            >
              Funeraria
            </a>
          </p>
          <p
            v-for="(locatie, index) in locationMixin.locaties.filter(locatie => locatie.funeraria)"
            class="subItem"
            :key="index"
          >
            <a
              @click="$router.push(`Funeraria?locatie=${locatie.locatie}`)"
            >
              {{ locatie.funeraria.type }} {{ locatie.locatie }}
            </a>
          </p>
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <p>
            <a
              class="text-uppercase"
              @click="$router.push('Aulas')"
            >
              Aula's
            </a>
          </p>
          <p
            class="subItem"
          >
            <a
              @click="$router.push('Aulas?locatie=Geel')"
            >
              Aula Geel
            </a>
          </p>
          <p
            class="subItem"
          >
            <a
              @click="$router.push('Aulas?locatie=Lichtaart')"
            >
              Aula Lichtaart
            </a>
          </p>
          <p
            class="subItem"
          >
            <a
              @click="$router.push('Aulas?locatie=Turnhout')"
            >
              Aula Turnhout
            </a>
          </p>
        </v-col>
        <v-col
          cols="12"
          lg="2"
        >
          <p>
            <a
              class="text-uppercase"
              @click="$router.push('ExtraDiensten')"
            >
              Extra diensten
            </a>
          </p>
          <p
            class="subItem"
          >
            <a
              @click="$router.push('BereidJeUitvaartVoor')"
            >
              Bereid je uitvaart voor
            </a>
          </p>
          <p
            class="subItem"
          >
            <a
              @click="$router.push('Uitvaartverzekering')"
            >
              Uitvaartverzekering
            </a>
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      class="my-5 py-5"
      fluid
    >
      <v-img
        class="logo float-left"
        :src="require('@/assets/images/logo.png')"
        style="min-width: 300px;"
        @click="$router.push('/')"
      />
      <v-spacer />
      <div
        class="float-left"
      >
        Geel - Kasterlee - Lichtaart - Merksplas - Beerse - Turnhout
        <br>
        014 58 84 65 - <a href="mailto:info@verstappen.be">info@verstappen.be</a> - BTW BE 0416.448.615
      </div>
      <div
        class="float-right"
      >
        <!-- <a
          @click="$router.push('Privacybeleid')"
        >
          Privacybeleid
        </a>
        <a
          class="ml-5"
          @click="$router.push('Cookiebeleid')"
        >
          Cookiebeleid
        </a> -->
      </div>
    </v-container>
  </v-container>
</template>

<script>
import locationMixin from '@/mixins/locationMixin'
import errorMixin from '@/mixins/errorMixin'

export default {
  name: 'TheFooter',
  mixins: [
    locationMixin,
    errorMixin
  ],
  methods: {
    ScrollToTop () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>
