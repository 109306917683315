<template>
  <veelgestelde-vragen />
</template>

<script>
import VeelgesteldeVragen from '../components/VeelgesteldeVragen'

export default {
  name: 'VeelgesteldeVragenView',
  components: {
    VeelgesteldeVragen
  }
}
</script>
