<template>
  <muziek />
</template>

<script>
import Muziek from '../components/Muziek.vue'

export default {
  name: 'MuziekView',
  components: {
    Muziek
  }
}
</script>
