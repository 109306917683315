<template>
  <v-container
    v-if="section"
    class="content pa-0"
    fluid
  >
    <v-form
      v-model="valid"
      ref="form"
    >
      <v-row
        class="alternatingContentRow pa-0"
      >
        <v-col
          cols="12"
          lg="12"
        >
          <v-img
            :src="require(`@/assets/images/Overlijdensberichten banner.jpg`)"
          />
        </v-col>
      </v-row>
      <v-row
        class="alternatingContentRow"
        :id="idTitle"
      >
        <v-col
          class="center my-8 py-8"
          cols="12"
          lg="8"
        >
          <h1
            class="text-center"
          >
            <a
              v-if="selected"
              class="noUnderline"
              title="Terug"
              @click="Select(sections.overlijdensberichten, null, selected)"
            >
              <v-icon
                class="iconBack iconPrimaryColor"
              >
                mdi-arrow-left-circle
              </v-icon>
            </a>
            {{ section.title }}
          </h1>
          <v-row
            justify="center"
          >
            <v-col
              cols="12"
              lg="12"
            >
              <p
                class="text-center"
                v-html="section.text"
              />
            </v-col>
            <v-col
              cols="12"
              lg="3"
            >
              <control-select
                v-if="section === sections.overlijdensberichten"
                v-model="idFiliaal"
                dense
                itemValue="idFiliaal"
                itemText="naam"
                :items="filiaal"
                label="Locatie"
                outlined
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-if="PageList.length === 0"
        class="alternatingContentRow"
        justify="center"
      >
        <v-col
          class="font-italic my-8 py-8 text-center"
          cols="12"
          lg="8"
        >
          Er zijn momenteel geen overlijdensberichten om weer te geven.
        </v-col>
      </v-row>
      <v-row
        v-show="!selected || (selected && selected === overlijdensbericht.idDossier)"
        v-for="(overlijdensbericht, index) in PageList"
        :class="selected ? 'selectedContentRow' : 'alternatingContentRow'"
        :key="index"
      >
        <v-col
          v-if="!selected || selected === overlijdensbericht.idDossier"
          class="center my-8 py-8"
          cols="12"
          :id="overlijdensbericht.idDossier"
          lg="8"
        >
          <v-row
            v-if="selected === overlijdensbericht.idDossier"
          >
            <v-col
              cols="12"
              lg="12"
            >
              <p
                class="font-weight-bold"
              >
                <span
                  class="pb-5"
                >
                  {{ section.description }}
                </span>
                <br>
              </p>
            </v-col>
          </v-row>
          <v-row
          >
            <v-col
              cols="12"
              lg="3"
            >
              <img
                v-if="overlijdensbericht.foto.loaded"
                class="overlijdensberichtFoto"
                :src="overlijdensbericht.foto.idBlob ? overlijdensbericht.foto.blobDataString : require(`@/assets/images/Overlijdensberichten placeholder ${overlijdensbericht.foto.placeholder}.jpg`)"
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
            >
              <h2
                class="pb-0"
              >
                <span
                  class="colorPrimary"
                  v-html="OverlijdensberichtenGetTekst(overlijdensbericht, 'naam')"
                />
              </h2>
              <p
                class="pt-0"
              >
                <span
                  v-html="OverlijdensberichtenGetTekst(overlijdensbericht, 'echtgenoot')"
                />
                <br>
                <span
                  v-html="OverlijdensberichtenGetTekst(overlijdensbericht, 'geboortePlaatsDatum')"
                />
                <br>
                <span
                  v-html="OverlijdensberichtenGetTekst(overlijdensbericht, 'overlijdensPlaatsDatum')"
                />
              </p>
              <p>
                <span
                  v-html="OverlijdensberichtenGetTekst(overlijdensbericht, 'uitvaart')"
                />
                <br>
                <span
                  v-html="OverlijdensberichtenGetTekst(overlijdensbericht, 'groeten')"
                />
              </p>
              <p
                class="mb-0 pb-0"
              >
                <span
                  v-html="OverlijdensberichtenGetTekst(overlijdensbericht, 'extraVermelding')"
                />
              </p>
              <p
                class="mb-0 pb-0"
              >
                <span
                  v-html="OverlijdensberichtenGetTekst(overlijdensbericht, 'woonplaats')"
                />
              </p>
            </v-col>
            <v-col
              cols="12"
              lg="3"
            >
              <div
                v-if="section.showControls"
              >
                <v-btn
                  v-if="overlijdensbericht.rouwbriefZichtbaar && overlijdensbericht.idBlobRouwbrief"
                  block
                  class="btnSubtle mb-3"
                  color="primary"
                  tile
                  @click="Download(overlijdensbericht.idBlobRouwbrief, `Rouwbrief ${overlijdensbericht.naamOverschrijven ? overlijdensbericht.naam : overlijdensbericht.naamStandaard}`, false)"
                >
                  Rouwbrief
                </v-btn>
                <v-btn
                  v-if="overlijdensbericht.condolerenToestaan"
                  block
                  class="btnSubtle mb-3"
                  color="primary"
                  tile
                  @click="Select(sections.condoleren, overlijdensbericht.idDossier)"
                >
                  Online condoleren
                </v-btn>
                <v-btn
                  v-if="BloemenBestellenToestaan(overlijdensbericht)"
                  block
                  class="btnSubtle mb-3"
                  color="primary"
                  tile
                  @click="Select(sections.bloemen, overlijdensbericht.idDossier)"
                >
                  Bloemen bestellen
                </v-btn>
                <v-btn
                  v-if="overlijdensbericht.koffietafelToestaan"
                  block
                  class="btnSubtle mb-3"
                  color="primary"
                  tile
                  @click="Select(sections.koffietafel, overlijdensbericht.idDossier)"
                >
                  Aanwezig op de koffietafel?
                </v-btn>
                <v-btn
                  v-if="overlijdensbericht.livestreamToestaan && new Date(overlijdensbericht.deadline.livestreamStop).getTime() > new Date().getTime()"
                  block
                  class="btnSubtle"
                  color="secondary"
                  tile
                  @click="SelectLivestream(overlijdensbericht.idDossier)"
                >
                  Volg de dienst online
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <template
        v-if="section === sections.bloemen"
      >
        <webshop
          v-if="Dossier()"
          :dossier="Dossier()"
          :idBetalingOnline="section.idBetalingOnline"
          type="bloemstukken"
          @success="WebshopSuccess($event)"
        />
      </template>
      <v-row
        v-else-if="section === sections.condoleren"
        justify="center"
      >
        <v-col
          class="my-8 py-8"
          cols="12"
          lg="8"
        >
          <v-row>
            <v-col
              cols="12"
              lg="3"
            >
              <span
                class="font-weight-bold"
              >
                Gedenktekst
              </span>
            </v-col>
            <v-col
              cols="12"
              lg="8"
            >
              <v-textarea
                v-model="section.data.tekst"
                auto-grow
                base-color="primary"
                bg-color="white"
                color="primary"
                density="compact"
                variant="outlined"
                :rules="[validationMixin.rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              lg="1"
            >
            </v-col>
            <v-col
              cols="12"
              lg="3"
            >
              <span
                class="font-weight-bold"
              >
                Vanwege
              </span>
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <v-text-field
                v-model="section.data.familienaam"
                base-color="primary"
                bg-color="white"
                color="primary"
                density="compact"
                label="Uw naam"
                variant="outlined"
                :rules="[validationMixin.rules.max50Characters, validationMixin.rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <v-text-field
                v-model="section.data.voornaam"
                base-color="primary"
                bg-color="white"
                color="primary"
                density="compact"
                label="Uw voornaam"
                variant="outlined"
                :rules="[validationMixin.rules.max50Characters, validationMixin.rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              lg="1"
            >
            </v-col>
            <v-col
              cols="12"
              lg="3"
            >
              <span
                class="font-weight-bold"
              >
                Uw e-mail
              </span>
            </v-col>
            <v-col
              cols="12"
              lg="8"
            >
              <v-text-field
                v-model="section.data.emailadres"
                base-color="primary"
                bg-color="white"
                color="primary"
                density="compact"
                label="U ontvangt een kopie van uw bericht"
                variant="outlined"
              />
            </v-col>
            <v-col
              cols="12"
              lg="1"
            >
            </v-col>
            <v-col
              cols="12"
              lg="3"
            >
            </v-col>
            <v-col
              cols="12"
              lg="8"
            >
              <vue-recaptcha
                v-if="reCaptchaKey"
                :sitekey="reCaptchaKey"
                @error="section.robot = false"
                @expired="section.robot = false"
                @render="section.robot = false"
                @verify="section.robot = true"
              />
              <v-btn
                class="my-3"
                color="primary"
                tile
                @click="Verzend()"
              >
                Verzend condoleance
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-else-if="section === sections.koffietafel"
        justify="center"
      >
        <v-col
          class="my-8 py-8"
          cols="12"
          lg="8"
        >
          <v-row>
            <v-col
              cols="12"
              lg="3"
            >
              <span
                class="font-weight-bold"
              >
                Uw gegevens
              </span>
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <v-text-field
                v-model="section.data.naam"
                base-color="primary"
                bg-color="white"
                color="primary"
                density="compact"
                label="Uw naam"
                variant="outlined"
                :rules="[validationMixin.rules.max50Characters, validationMixin.rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <v-text-field
                v-model="section.data.voornaam"
                base-color="primary"
                bg-color="white"
                color="primary"
                density="compact"
                label="Uw voornaam"
                variant="outlined"
                :rules="[validationMixin.rules.max50Characters, validationMixin.rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              lg="1"
            >
            </v-col>
            <v-col
              cols="12"
              lg="3"
            >
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <v-text-field
                v-model="section.data.straat"
                base-color="primary"
                bg-color="white"
                color="primary"
                density="compact"
                label="Uw straat en huisnummer"
                variant="outlined"
                :rules="[validationMixin.rules.max50Characters]"
              />
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <v-autocomplete
                v-model="section.data.idPost"
                base-color="primary"
                bg-color="white"
                color="primary"
                density="compact"
                item-value="idPost"
                item-title="tekst"
                :items="post"
                label="Uw gemeente"
                variant="outlined"
              />
            </v-col>
            <v-col
              cols="12"
              lg="1"
            >
            </v-col>
            <v-col
              cols="12"
              lg="3"
            >
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <v-text-field
                v-model="section.data.telefoonnummer"
                base-color="primary"
                bg-color="white"
                color="primary"
                density="compact"
                label="Uw telefoonnummer"
                variant="outlined"
                :rules="[validationMixin.rules.max50Characters]"
              />
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <v-text-field
                v-model="section.data.emailadres"
                base-color="primary"
                bg-color="white"
                color="primary"
                density="compact"
                label="Uw e-mailadres"
                variant="outlined"
                :rules="[validationMixin.rules.email, validationMixin.rules.max50Characters]"
              />
            </v-col>
            <v-col
              cols="12"
              lg="1"
            >
            </v-col>
            <v-col
              cols="12"
              lg="3"
            >
              <span
                class="font-weight-bold"
              >
                U bent met
              </span>
            </v-col>
            <v-col
              cols="12"
              lg="4"
            >
              <v-text-field
                v-model.number="section.data.aantalPersonen"
                base-color="primary"
                bg-color="white"
                color="primary"
                density="compact"
                label="Aantal personen"
                :min="0"
                variant="outlined"
                type="number"
                :rules="[validationMixin.rules.naturalNumber, validationMixin.rules.required]"
              />
            </v-col>
            <v-col
              class="mb-3"
              cols="12"
              lg="2"
            >
              <v-radio-group
                v-model="section.data.aanwezig"
                :rules="[validationMixin.rules.requiredCheckbox]"
              >
                <v-radio
                  :value="true"
                  base-color="primary"
                  bg-color="white"
                  color="primary"
                  label="Aanwezig"
                  variant="outlined"
                />
              </v-radio-group>
            </v-col>
            <v-col
              class="mb-3"
              cols="12"
              lg="2"
            >
              <v-radio-group
                v-model="section.data.aanwezig"
                :rules="[validationMixin.rules.requiredCheckbox]"
              >
                <v-radio
                  :value="false"
                  base-color="primary"
                  bg-color="white"
                  color="primary"
                  label="Afwezig"
                  variant="outlined"
                />
              </v-radio-group>
            </v-col>
            <v-col
              cols="12"
              lg="3"
            >
            </v-col>
            <v-col
              cols="12"
              lg="8"
            >
              <vue-recaptcha
                v-if="reCaptchaKey"
                :sitekey="reCaptchaKey"
                @error="section.robot = false"
                @expired="section.robot = false"
                @render="section.robot = false"
                @verify="section.robot = true"
              />
              <v-btn
                class="my-3"
                color="primary"
                tile
                @click="Verzend()"
              >
                Bevestig uw komst
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-else-if="!selected && PageCount > 1"
        justify="center"
      >
        <v-col
          class="my-8 py-8"
          cols="12"
          lg="8"
        >
          <v-pagination
            v-model="page"
            color="primary"
            :length="PageCount"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-dialog
      v-model="section.succeeded"
      width="450px"
      persistent
    >
      <v-card
        class="pa-3"
      >
        <h2
          class="colorSecondary pb-1 pt-3 text-center"
          v-html="messages.success"
        />
        <p
          class="colorSecondary text-center"
        >
          Klik op 'OK' om terug te gaan naar het overzicht.
          <br>
          <v-btn
            class="mt-5"
            color="primary"
            tile
            @click="section.idBetalingOnline = null; Select(sections.overlijdensberichten, null, selected)"
          >
            OK
          </v-btn>
        </p>
      </v-card>
    </v-dialog>
    <overlijdensberichten-livestream
      v-if="sections.livestream.selected"
      v-model="sections.livestream.selected"
    />
  </v-container>
</template>

<script>
import blobMixin from '@/mixins/blobMixin'
import commonMixin from '@/mixins/commonMixin'
import constantMixin from '@/mixins/constantMixin'
import errorMixin from '@/mixins/errorMixin'
import listMixin from '@/mixins/listMixin'
import validationMixin from '@/mixins/validationMixin'
import blobService from '@/services/blobService'
import configurationService from '@/services/configurationService'
import klantService from '@/services/klantService'
import { VueRecaptcha } from 'vue-recaptcha'
import OverlijdensberichtenLivestream from './OverlijdensberichtenLivestream.vue'
import ControlSelect from './ControlSelect.vue'
import Webshop from './Webshop.vue'

export default {
  name: 'OverlijdensberichtenComponent',
  mixins: [
    blobMixin,
    commonMixin,
    constantMixin,
    errorMixin,
    listMixin,
    validationMixin
  ],
  components: {
    VueRecaptcha,
    OverlijdensberichtenLivestream,
    ControlSelect,
    Webshop
  },
  data () {
    return {
      betalingsmethode: [],
      filiaal: [],
      idFiliaal: null,
      idStepTitle: 'stepTitle',
      idTitle: 'title',
      messages: {
        success: null
      },
      numberPerPage: 50,
      page: 1,
      placeholderNumber: null,
      placeholderNumberMax: 4,
      post: [],
      reCaptchaKey: null,
      section: null,
      sections: {
        bloemen: {
          description: 'U bestelt bloemen voor',
          idBetalingOnline: null,
          showControls: false,
          succeeded: false,
          text: 'Bloemen kunnen troost bieden bij het afscheid van een geliefde.<br>U kan kiezen uit ons assortiment bloemstukken.',
          title: 'Bloemen'
        },
        condoleren: {
          data: {},
          description: 'U condoleert de naasten van',
          robot: false,
          showControls: false,
          succeeded: false,
          text: 'Uw woorden zijn een steun in moeilijke tijden voor de achterblijvers van de overleden persoon.<br>Via deze pagina condoleert u gemakkelijk en veilig online.',
          title: 'Online condoleren'
        },
        koffietafel: {
          data: {},
          description: 'U komt naar de koffietafel van',
          robot: false,
          showControls: false,
          succeeded: false,
          text: 'Bent u uitgenodigd voor de koffietafel? Bevestig dan hier uw komst.<br>De familie en vrienden kijken uit naar uw aanwezigheid.',
          title: 'Koffietafel'
        },
        livestream: {
          selected: null
        },
        overlijdensberichten: {
          description: '',
          showControls: true,
          succeeded: false,
          text: 'Van deze mensen hebben we recent afscheid moeten nemen.<br>Ze zullen niet vergeten worden.',
          title: 'Overlijdensberichten'
        }
      },
      selected: null,
      valid: false
    }
  },
  mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    blobService.Init(this)
    configurationService.Init(this)
    klantService.Init(this)
    this.section = this.sections.overlijdensberichten
    this.GetOverlijdensberichtList()
    this.CheckIfReturnAfterPayment()
    this.GetConfiguration()
  },
  watch: {
    PageList (value) {
      this.LoadFotos('Overlijdensberichten', value)
    },
    page () {
      this.GoToElement(this.idTitle)
    }
  },
  computed: {
    OverlijdensberichtenFilteredList () {
      return this.idFiliaal ? this.listMixin.list.filter(x => x.idFiliaal === this.idFiliaal) : this.listMixin.list
    },
    PageList () {
      return this.CommonMixin_GetPageList(this.OverlijdensberichtenFilteredList, this.page, this.numberPerPage)
    },
    PageCount () {
      return this.CommonMixin_GetPageCount(this.OverlijdensberichtenFilteredList, this.numberPerPage)
    },
    Robot () {
      return this.section.robot || !this.reCaptchaKey
    }
  },
  methods: {
    BloemenBestellenToestaan (overlijdensbericht) {
      return overlijdensbericht.bloemenBestellenToestaan
    },
    async CheckIfReturnAfterPayment () {
      if (this.$route.query && this.$route.query.idDossier) {
        if (this.$route.query.idBetalingOnline) {
          this.sections.bloemen.idBetalingOnline = this.$route.query.idBetalingOnline
          await this.Select(this.sections.bloemen, this.$route.query.idDossier, this.idStepTitle)
        } else {
          await new Promise(resolve => setTimeout(resolve, 100))
          this.GoToElement(this.$route.query.idDossier)
        }
      }
    },
    Dossier (idDossier = this.selected) {
      return idDossier ? this.listMixin.list.find(x => x.idDossier === idDossier) : null
    },
    async Download (idBlob, bestandsnaam, download = true) {
      const blob = {
        bestandsnaam: bestandsnaam,
        idBlob: idBlob
      }
      await download ? this.BlobMixin_DownloadExisting(blob) : this.BlobMixin_OpenExisting(blob)
    },
    async GetBlobFoto (type, idBlob) {
      let resp = null

      if (type === 'Overlijdensberichten') {
        resp = await klantService.GetOverlijdensberichtDetailThumbnail(idBlob)
      }

      if (resp && (resp.data || resp.data !== '')) {
        return this.CommonMixin_Clone(resp.data)
      } else {
        return null
      }
    },
    async GetConfiguration () {
      const resp = await configurationService.GetReCaptchaSiteKey()

      if (resp && (resp.data || resp.data !== '')) {
        this.reCaptchaKey = resp.data
      }
    },
    async GetOverlijdensberichtListListFiliaal () {
      const resp = await klantService.GetOverlijdensberichtListListFiliaal()

      if (resp && (resp.data || resp.data !== '')) {
        this.filiaal = resp.data
      }
    },
    async GetOverlijdensberichtDetailLists () {
      await this.GetOverlijdensberichtListListFiliaal()
      await this.GetOverlijdensberichtDetailKoffietafelListPost()
    },
    async GetOverlijdensberichtDetailKoffietafelListPost () {
      const resp = await klantService.GetOverlijdensberichtDetailKoffietafelListPost()

      if (resp && (resp.data || resp.data !== '')) {
        this.post = resp.data
      }
    },
    async GetOverlijdensberichtList () {
      this.CommonMixin_Load(this.$options.name, true)
      const resp = await klantService.GetOverlijdensberichtList()

      if (resp && (resp.data || resp.data !== '')) {
        this.listMixin.list = this.CommonMixin_Clone(resp.data)
      }

      this.SetPlaceholderNumbers()
      await this.GetOverlijdensberichtDetailLists()
      this.CommonMixin_Load(this.$options.name, false)
    },
    GoToElement (idElement) {
      const element = document.getElementById(idElement)

      if (element) {
        const coordinate = element.getBoundingClientRect().top + window.pageYOffset - document.getElementById('header').getBoundingClientRect().height - document.getElementById('header2').getBoundingClientRect().height
        window.scrollTo({ top: coordinate, behavior: 'smooth' })
      }
    },
    async LoadFotos (type, list) {
      this.CommonMixin_Load(this.$options.name, true)

      await list.forEach(async item => {
        if (item.foto && !item.foto.loaded) {
          if (item.foto.idBlob) {
            item.foto = await this.GetBlobFoto(type, item.foto.idBlob) ?? item.foto
          }

          item.foto.loaded = true
        }
      })

      this.$forceUpdate()
      this.CommonMixin_Load(this.$options.name, false)
    },
    OverlijdensberichtenGetTekst (overlijdensbericht, property) {
      return overlijdensbericht[`${property}Tekst`]
    },
    async Select (section, id, goto = null) {
      section.data = {
        idDossier: id
      }

      this.section = section
      this.section.succeeded = false
      this.selected = id

      if (goto) {
        await new Promise(resolve => setTimeout(resolve, 100))
        this.GoToElement(goto)
      } else {
        this.GoToElement(this.idTitle)
      }
    },
    SelectLivestream (idDossier) {

      this.sections.livestream.selected = this.PageList.find(x => x.idDossier === idDossier)
      this.GoToElement(idDossier)
    },
    SetPlaceholderNumbers () {
      this.listMixin.list.filter(element => !element.foto.idBlob).forEach(element => {
        if (!this.placeholderNumber || this.placeholderNumber === this.placeholderNumberMax) {
          this.placeholderNumber = 1
        } else {
          this.placeholderNumber++
        }

        element.foto.placeholder = this.placeholderNumber
      })
    },
    async Verzend () {
      this.$refs.form.validate()

      if (this.valid) {
        this.$refs.form.validate()

        if (this.Robot) {
          this.CommonMixin_Load(this.$options.name, true)

          let resp = null
          let message = null

          if (this.section === this.sections.condoleren) {
            resp = await klantService.SaveOverlijdensberichtDetailCondoleren(this.section.data)
            message = 'Uw condoleance werd doorgegeven.'
          } else if (this.section === this.sections.koffietafel) {
            resp = await klantService.SaveOverlijdensberichtDetailKoffietafel(this.section.data)
            message = `Uw ${this.section.data.aanwezig ? 'aan' : 'af'}wezigheid werd doorgegeven.`
          }

          if (resp && (resp.data || resp.data !== '')) {
            this.messages.success = message
            this.section.succeeded = true
          }

          this.CommonMixin_Load(this.$options.name, false)
        } else {
          this.CommonMixin_Snackbar(this.textMixin.messages.captcha, 'error')
        }
      }
    },
    WebshopSuccess (value) {
      this.messages.success = value.message
      this.section.succeeded = value.succeeded
    }
  }
}
</script>
