<template>
  <condoleances />
</template>

<script>
import Condoleances from '../components/Condoleances.vue'

export default {
  name: 'CondoleancesView',
  components: {
    Condoleances
  }
}
</script>
