<template>
  <overlijdensberichten />
</template>

<script>
import Overlijdensberichten from '../components/Overlijdensberichten.vue'

export default {
  name: 'OverlijdensberichtenView',
  components: {
    Overlijdensberichten
  }
}
</script>
