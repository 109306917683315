<template>
  <herinneringsjuwelen />
</template>

<script>
import Herinneringsjuwelen from '../components/Herinneringsjuwelen.vue'

export default {
  name: 'HerinneringsjuwelenView',
  components: {
    Herinneringsjuwelen
  }
}
</script>
