<template>
  <over-ons />
</template>

<script>
import OverOns from '../components/OverOns'

export default {
  name: 'OverOnsView',
  components: {
    OverOns
  }
}
</script>
