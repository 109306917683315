<template>
  <covid-19 />
</template>

<script>
import Covid19 from '../components/COVID19.vue'

export default {
  name: 'COVID19View',
  components: {
    Covid19
  }
}
</script>
