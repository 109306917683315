// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import App from './App.vue'
import { createApp } from 'vue'
import Emitter from 'tiny-emitter'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import vueCookie from './plugins/vue-cookie'
import VueMask from 'v-mask'
import cacheMixin from '@/mixins/cacheMixin'
import './globals'
import registerBaseComponents from './plugins/base'
import installChartistPlugin from './plugins/chartist'
import './plugins/vee-validate'

const app = createApp(App)

app.config.productionTip = false
app.config.globalProperties.$msalInstance = {}
app.config.globalProperties.$emitter = new Emitter()
app.use(router)
app.use(store)
app.use(vuetify)
app.use(VueMask)
app.use(vueCookie)
app.mixin(cacheMixin)

registerBaseComponents(app)
installChartistPlugin(app)

app.mount('#app')