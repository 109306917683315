<template>
  <mini-urne />
</template>

<script>
import MiniUrne from '../components/MiniUrne.vue'

export default {
  name: 'MiniUrneView',
  components: {
    MiniUrne
  }
}
</script>
