<template>
  <v-container
    v-if="authenticationMixin.checkedLogin"
    class="content pa-0"
    fluid
  >
    <v-row>
      <v-col
        class="center pt-8"
        cols="12"
        lg="12"
      >
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            lg="8"
          >
            <v-row>
              <v-col
                class=""
                cols="12"
                lg="6"
              >
                <h2>
                  Aanwezigen koffietafel
                </h2>
                <p>
                  Deze {{ GenodigdenTekst(DossierKoffietafelInschrijvingenAantal(true)) }} komst bevestigd.
                </p>
              </v-col>
              <v-col
                class="hidden-lg-and-up mb-3"
                cols="12"
                lg="6"
              >
                <v-row
                  v-for="(row, index) in aanwezig"
                  class="mt-2 py-3 px-3"
                  :class="index % 2 === 0 ? 'alternatingContentRow2' : ''"
                  justify="center"
                  :key="index"
                >
                  <v-col
                    cols="12"
                    lg="12"
                  >
                    <span
                      class="colorPrimary font-weight-bold"
                    >
                      {{ row.volledigeNaam }}
                    </span>
                    <br>
                    {{ row.aantalPersonen }} {{ row.aantalPersonen === 1 ? 'persoon' : 'personen' }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                lg="6"
              >
                <h2>
                  Afwezigen koffietafel
                </h2>
                <p>
                  Deze {{ GenodigdenTekst(DossierKoffietafelInschrijvingenAantal(false)) }} komst afgezegd.
                </p>
              </v-col>
              <v-col
                class="hidden-lg-and-up mb-3"
                cols="12"
                lg="6"
              >
                <v-row
                  v-for="(row, index) in afwezig"
                  class="mt-2 py-3 px-3"
                  :class="index % 2 === 0 ? 'alternatingContentRow2' : ''"
                  justify="center"
                  :key="index"
                >
                  <v-col
                    cols="12"
                    lg="12"
                  >
                    <span
                      class="colorPrimary font-weight-bold"
                    >
                      {{ row.volledigeNaam }}
                    </span>
                    <br>
                    {{ row.aantalPersonen }} {{ row.aantalPersonen === 1 ? 'persoon' : 'personen' }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          v-for="row in NumberOfRows"
          class="hidden-md-and-down mt-2 py-3 px-3"
          :class="row % 2 === 1 ? 'alternatingContentRow2' : ''"
          justify="center"
          :key="row"
        >
          <v-col
            class="py-2"
            cols="12"
            lg="8"
          >
            <v-row>
              <v-col
                cols="12"
                lg="6"
              >
                <div
                  v-if="aanwezig[row - 1]"
                >
                  <span
                    class="colorPrimary font-weight-bold"
                  >
                    {{ aanwezig[row - 1].volledigeNaam }}
                  </span>
                  <br>
                  {{ aanwezig[row - 1].aantalPersonen }} {{ aanwezig[row - 1].aantalPersonen === 1 ? 'persoon' : 'personen' }}
                </div>
              </v-col>
              <v-col
                cols="12"
                lg="6"
              >
                <div
                  v-if="afwezig[row - 1]"
                >
                  <span
                    class="colorPrimary font-weight-bold"
                  >
                    {{ afwezig[row - 1].volledigeNaam }}
                  </span>
                  <br>
                  {{ afwezig[row - 1].aantalPersonen }} {{ afwezig[row - 1].aantalPersonen === 1 ? 'persoon' : 'personen' }}
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'
import commonMixin from '@/mixins/commonMixin'
import errorMixin from '@/mixins/errorMixin'
import listMixin from '@/mixins/listMixin'
import klantService from '@/services/klantService'

export default {
  name: 'KoffietafelComponent',
  mixins: [
    authenticationMixin,
    commonMixin,
    errorMixin,
    listMixin
  ],
  data () {
    return {
      aanwezig: [],
      afwezig: []
    }
  },
  mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.AuthenticationMixin_CheckIfLoggedIn()
    this.$store.state.headers.raadplegen = true
    klantService.Init(this)
    this.GetUitvaartKoffietafelInschrijvingList()
  },
  beforeUnmount () {
    this.$store.state.headers.raadplegen = false
  },
  computed: {
    NumberOfRows () {
      return this.aanwezig.length > this.afwezig.length ? this.aanwezig.length : this.afwezig.length
    }
  },
  methods: {
    DossierKoffietafelInschrijvingenAantal (aanwezig = null) {
      const list = aanwezig ? this.aanwezig : this.afwezig

      return list.reduce((sum, object) => {
        return sum + object.aantalPersonen
      }, 0)
    },
    DossierKoffietafelInschrijvingen (aanwezig = null) {
      return this.listMixin.list.filter(x => x.aanwezig === aanwezig)
    },
    GenodigdenTekst (number) {
      return number === 1 ? 'ene genodigde heeft zijn/haar' : `${number} genodigden hebben hun`
    },
    async GetUitvaartKoffietafelInschrijvingList () {
      this.CommonMixin_Load(this.$options.name, true)
      const resp = await klantService.GetUitvaartKoffietafelInschrijvingList()

      if (resp && (resp.data || resp.data !== '')) {
        this.listMixin.list = resp.data
        this.aanwezig = this.DossierKoffietafelInschrijvingen(true)
        this.afwezig = this.DossierKoffietafelInschrijvingen(false)
      }

      this.CommonMixin_Load(this.$options.name, false)
    }
  }
}
</script>
