<template>
  <v-container
    v-if="authenticationMixin.checkedLogin"
    class="content pa-0"
    fluid
  >
    <v-row
      class="alternatingContentRow pa-0"
    >
    </v-row>
    <v-row
      class="alternatingContentRow my-8 py-8"
    >
      <v-col
        class="center"
        cols="12"
        lg="10"
      >
        <h2
          class="colorPrimary text-center"
        >
          Draaiboek uitvaart
        </h2>
        <p
          class="text-center"
        >
          Samen met u werken wij een persoonlijke uitvaart uit.
          <br>
          Dit doen wij op basis van de door u aangeleverde informatie.
          <br>
          Hier staat u dan uiteraard nog vrij om wijzigingen aan te brengen waar gewenst.
        </p>
        <p
          class="text-center"
        >
          Mogen wij u vragen om de twee onderstaande vragen te beantwoorden.
          <br>
          Zij vormen mee de basis van de uitvaart.
          <br>
          Wanneer we verdere informatie zoals muziek, teksten, foto's en eventuele symbolische handelingen van u ontvangen hebben,
          <br>
          zal u hier ook het draaiboek kunnen terugvinden om toed te keuren.
        </p>
      </v-col>
    </v-row>
    <v-row
      class="alternatingContentRow my-8 py-8"
    >
      <v-col
        class="center"
        cols="12"
        lg="4"
      >
        <h2
          class="text-center"
        >
          1. Algemene info
        </h2>
        <h4>
          Aanspreking
        </h4>
        <p>
          Hoe werd de overledene in de familie (pa, ma, bompa, bomma, …) en door anderen aangesproken (nonkel …, tante …, …) en hoe wenst u dat ik hem/haar aanspreek of over hem/haar spreek doorheen de plechtigheid?
        </p>
        <v-textarea
          v-model="algemeneInfo.aanspreking"
          auto-grow
          base-color="primary"
          bg-color="white"
          color="primary"
          density="compact"
          rows="3"
          variant="outlined"
        />
        <h4>
          Aard van de plechtigheid
        </h4>
        <p>
          Wenst u een neutrale plechtigheid of eerder een christelijk geïnspireerde plechtigheid?
          Indien christelijk, is er een kruisteken gewenst bij begin en einde?
          Is er een formeel gebed gewenst?
          <span
            class="text-body-2 font-italic"
          >
            (We lezen in onze aula in principe geen evangelie.)
          </span>
        </p>
        <v-textarea
          v-model="algemeneInfo.aardPlechtigheid"
          auto-grow
          base-color="primary"
          bg-color="white"
          color="primary"
          density="compact"
          rows="3"
          variant="outlined"
        />
        <v-btn
          class="float-right"
          color="primary"
          size="small"
          tile
          @click="SaveGeneralInfo()"
        >
          Info opslaan
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'
import commonMixin from '@/mixins/commonMixin'
import constantMixin from '@/mixins/constantMixin'
import errorMixin from '@/mixins/errorMixin'
import klantService from '@/services/klantService'

export default {
  name: 'RouwdrukwerkComponent',
  mixins: [
    authenticationMixin,
    commonMixin,
    constantMixin,
    errorMixin
  ],
  data () {
    return {
      algemeneInfo: {}
    }
  },
  mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.AuthenticationMixin_CheckIfLoggedIn()
    this.$store.state.headers.samenstellen = true
    klantService.Init(this)
    this.GetUitvaartDraaiboek()
  },
  beforeUnmount () {
    this.$store.state.headers.samenstellen = false
  },
  methods: {
    async GetUitvaartDraaiboek () {
      this.CommonMixin_Load(this.$options.name, true)
      const resp = await klantService.GetUitvaartDraaiboek()

      if (resp && (resp.data || resp.data !== '')) {
        this.algemeneInfo = resp.data
      }

      this.CommonMixin_Load(this.$options.name, false)
    },
    SaveGeneralInfo () {
      this.CommonMixin_Load(this.$options.name, true)
      const resp = klantService.SaveUitvaartDraaiboek(this.algemeneInfo)

      if (resp && (resp.data || resp.data !== '')) {
        this.$router.push('Uitvaart')
      }

      this.CommonMixin_Load(this.$options.name, false)
    }
  }
}
</script>
