<template>
  <v-container
    class="content pa-0"
    fluid
  >
    <v-row
      class="alternatingContentRow pa-0"
    >
      <v-col
        cols="12"
        lg="12"
      >
        <v-img
          :src="require(`@/assets/images/Nieuws banner.jpg`)"
        />
      </v-col>
    </v-row>
    <v-row
      class="alternatingContentRow"
    >
      <v-col
        class="center my-8 py-8"
        cols="12"
        lg="8"
      >
        <h1
          class="text-center"
        >
          Nieuws
        </h1>
        <v-row
          v-for="(article, index) in listMixin.list"
          :key="index"
          align="center"
          class="my-8 py-8"
          justify="center"
        >
          <v-col
            v-if="index % 2 > 0"
            cols="12"
            lg="6"
          >
            <v-row
              class="mr-10"
            >
              <v-col
                v-for="(photo, indexPhoto) in article.fotos"
                :key="`photo-${index}-${indexPhoto}`"
                class="pa-1"
                cols="12"
                :lg="photo.groot ? 12 : 6"
              >
                <v-img
                  :src="photo.foto.blobDataString"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
            <h2
              class="colorPrimary pb-0"
            >
              {{article.titel}}
            </h2>
            <span
              class="colorPrimary"
            >
              {{FormatMixin_FormatDateTime(article.datum, formatMixin.date.database, formatMixin.date.date)}}
            </span>
            <p
              v-for="(paragraph, indexParagraph) in article.tekst.split('\n\n')"
              :key="`paragraph-${indexParagraph}`"
              v-html="paragraph.replace('\n', '<br>')"
            />
          </v-col>
          <v-col
            v-if="index % 2 <= 0"
            cols="12"
            lg="6"
          >
            <v-row
              class="ml-10"
            >
              <v-col
                v-for="(photo, indexPhoto) in article.fotos"
                :key="`photo-${index}-${indexPhoto}`"
                class="pa-1"
                cols="12"
                :lg="photo.groot ? 12 : 6"
              >
                <v-img
                  :src="photo.foto.blobDataString"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import errorMixin from '@/mixins/errorMixin'
import listMixin from '@/mixins/listMixin'
import klantService from '@/services/klantService'

export default {
  name: 'NieuwsComponent',
  mixins: [
    errorMixin,
    listMixin
  ],
  async mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    klantService.Init(this)
    await this.GetNieuwsList()
  },
  methods: {
    async GetNieuwsList () {
      this.CommonMixin_Load(this.$options.name, true)
      const resp = await klantService.GetNieuwsList()

      if (resp && (resp.data || resp.data !== '')) {
        this.listMixin.list = this.CommonMixin_Clone(resp.data)
      }

      this.CommonMixin_Load(this.$options.name, false)
    }
  }
}
</script>
