<template>
  <v-container
    class="content pa-0"
    fluid
  >
    <v-row
      class="alternatingContentRow pa-0"
    >
      <v-col
        cols="12"
        lg="12"
      >
        <v-img
          :src="require(`@/assets/images/VeelgesteldeVragen banner.jpg`)"
        />
      </v-col>
    </v-row>
    <v-row
      class="alternatingContentRow"
    >
      <v-col
        class="center my-8 py-8"
        cols="12"
        lg="8"
      >
        <h1
          class="text-center"
        >
          Veelgestelde vragen
        </h1>
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            lg="8"
          >
            <p
              class="text-center"
            >
              Bij een uitvaart komt heel wat kijken op korte tijd.
              Niet alleen de voorbereiding van de uitvaart zelf, maar ook administratieve afhandelingen.
              Verstappen uitvaartzorg stelt u een uitgebreide uitvaartbrochure ter beschikking waarin we u stap voor stap informeren wat te doen bij overlijden.
              Hieronder vindt u alvast veelvoorkomende vragen en onze antwoorden terug.
            </p>
          </v-col>
        </v-row>
        <v-row
          justify="center"
        >
          <v-col
            v-if="Vragen.length > 0"
            cols="12"
            lg="8"
          >
            <v-container
              v-for="(vraag, index) in Vragen"
              class="clickAction faq"
              :key="index"
              @click="ToggleShow(vraag)"
            >
              <p>
                <v-icon
                  v-if="AllowToggle()"
                  class="float-right"
                  color="primary"
                >
                  mdi-chevron-{{ vraag.show ? 'up' : 'down'}}
                </v-icon>
                <span
                  class="font-weight-bold"
                  v-html="Highlight(vraag.vraag)"
                >
                </span>
              </p>
              <div
                v-if="ShowAntwoord(vraag)"
              >
                <p
                  v-for="(antwoord, index2) in vraag.antwoord"
                  :key="index2"
                  v-html="Highlight(antwoord)"
                >
                </p>
                <p
                  v-html="Highlight(UpdateLabel(vraag.update))"
                >
                </p>
              </div>
            </v-container>
          </v-col>
          <v-col
            v-else
            class="font-italic pt-3"
            cols="12"
            lg="8"
          >
            Geen resultaten gevonden voor deze zoekopdracht.
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              append-inner-icon="mdi-magnify"
              clearable
              :color="Vragen.length > 0 ? 'primary' : 'error'"
              density="compact"
              label="Zoeken"
              variant="outlined"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'VeelgesteldeVragen',
  data () {
    return {
      search: null,
      vragen: [
        {
          antwoord: [
            'Er kunnen nog steeds plechtigheden doorgaan zowel in de kerk, in de aula als op de begraafplaats. Voorwaarde is wel dat er niet meer dan 40 personen in het totaal aanwezig mogen zijn, inclusief priester of voorganger en onze mensen.',
            'Er mag een koffietafel zijn, maar ook hier met een maximum van 40 personen.',
            'Groetmomenten zijn er enkel voor de familie in de 1ste en eventueel 2de graad. Dit kan gedurende een uur op een afgesproken tijdstip. Er mogen max 2 à 3 personen tegelijkertijd in de groetkamer en er wordt gevraagd omwille van het samenscholingsverbod dat men ook op de parking of voor het gebouw niet blijft napraten. Personen die overleden zijn ten gevolge van het virus kunnen we helaas niet meer laten groeten.',
            'De voorbereidingen van een uitvaart worden voornamelijk via digitale weg en telefonisch besproken. Als u graag na het voorbereiden van de uitvaart nog even met ons samen zit om vragen te beantwoorden, dan kan dit nog steeds. Wij helpen u graag verder.'
          ],
          show: false,
          update: '20/10/2020',
          vraag: 'Hoe verloopt een uitvaart rekening houdende met COVID-19?'
        },
        {
          antwoord: [],
          show: false,
          update: '',
          vraag: 'Kan ik mijn eigen uitvaart vooraf al regelen?'
        },
        {
          antwoord: [],
          show: false,
          update: '',
          vraag: 'Stellen jullie een overlijden vast?'
        },
        {
          antwoord: [],
          show: false,
          update: '',
          vraag: 'Kan ik via Verstappen een overlijdensverzekering afsluiten?'
        }
      ],
      updateLabel: 'Update: '
    }
  },
  mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },
  computed: {
    Searching () {
      return this.search && this.search.trim()
    },
    Vragen () {
      if (this.Searching) {
        return this.vragen.filter(vraag => this.ContainsSearchString(vraag))
      } else {
        return this.vragen
      }
    }
  },
  methods: {
    AllowToggle () {
      return !this.Searching
    },
    ContainsSearchString (vraag) {
      const searchText = (this.search ?? '').toLowerCase()

      if (vraag.vraag.toLowerCase().includes(searchText) || this.UpdateLabel(vraag.update).toLowerCase().includes(searchText)) {
        return true
      } else {
        let contains = false

        vraag.antwoord.forEach(antwoord => {
          if (antwoord.toLowerCase().includes(searchText)) {
            contains = true
          }
        })

        return contains
      }
    },
    Highlight (tekst) {
      if (this.Searching) {
        return tekst.replace(new RegExp(this.search, 'ig'), '<span class="yellow">$&</span>')
      } else {
        return tekst
      }
    },
    ShowAntwoord (vraag) {
      if (this.Searching) {
        return vraag.show || this.ContainsSearchString(vraag)
      } else {
        return vraag.show
      }
    },
    ToggleShow (vraag) {
      if (this.AllowToggle()) {
        vraag.show = !vraag.show
      }
    },
    UpdateLabel (update) {
      return `${this.updateLabel}${update}`
    }
  }
}
</script>
