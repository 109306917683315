<template>
  <uitvaartverzekering />
</template>

<script>
import Uitvaartverzekering from '../components/Uitvaartverzekering.vue'

export default {
  name: 'UitvaartverzekeringView',
  components: {
    Uitvaartverzekering
  }
}
</script>
