import axios from 'axios'

let _svc
let _that

export default {
  // ReCaptcha
  GetReCaptchaSiteKey: () => {
    try {
      return _svc.get('Configuration/ReCaptcha/SiteKey')
    } catch {
      return null
    }
  },

  // Other
  Init: (that) => {
    _that = that

    _svc = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })

    _svc.interceptors.response.use(
      response => response,
      error => {
        _that.ErrorMixin_HandleError(error)
      }
    )
  }
}
