<template>
  <account />
</template>

<script>
import Account from '../components/Account.vue'

export default {
  name: 'AccountView',
  components: {
    Account
  }
}
</script>
