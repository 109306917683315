<template>
  <div
    @mouseleave="MouseLeave()"
    @mouseover="MouseOver()"
  >
    <v-text-field
      v-if="!readonly && !disabled && (hovering || focus)"
      v-model="date"
      :dense="dense"
      :disabled="disabled"
      :label="label"
      :readonly="readonly"
      ref="control"
      :rules="Rule"
      type="date"
      @blur="focus = false"
      @change="$emit('change', $event)"
      @click="DateInputReset()"
      @focus="DateFocus()"
      @keydown="DateKeydown($event)"
    />
    <v-text-field
      v-if="readonly || disabled || !(hovering || focus) || focusWrong"
      :dense="dense"
      :disabled="disabled"
      :label="label"
      :readonly="readonly"
      :rules="Rule"
      :value="DateText"
      @focus="FocusWrong()"
    />
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ControlDate',
  props: {
    autocomplete: {
      default: true,
      type: Boolean
    },
    dense: Boolean,
    disabled: Boolean,
    label: String,
    readonly: Boolean,
    required: Boolean,
    requiredText: {
      default: 'Required.',
      type: String
    },
    value: String
  },
  data () {
    return {
      date: null,
      dateInput: [],
      focus: false,
      focusWrong: false,
      hovering: false,
      requiredRule: value => !!value || this.requiredText
    }
  },
  mounted () {
    this.FormatData()
  },
  computed: {
    DateText () {
      return this.date ? moment(this.date, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
    },
    Rule () {
      if (this.required) {
        return [this.requiredRule]
      } else {
        return []
      }
    },
    ShowControls () {
      return !this.readonly && this.hovering
    }
  },
  watch: {
    date () {
      this.Emit()
    },
    readonly () {
      if (this.readonly) {
        this.FormatData()
      }
    },
    value () {
      this.FormatData()
    }
  },
  methods: {
    DateFocus () {
      this.focus = true
      this.DateInputReset()
    },
    DateKeydown (value) {
      if (this.autocomplete) {
        if (parseInt(value.key)) {
          this.dateInput.push(value.key)
        } else if (value.key === 'Backspace' || (value.key === 'Tab' && value.shiftKey)) {
          this.DateInputReset()
        } else if (value.key === 'Enter' && value.shiftKey && this.dateInput.length) {
          const today = new Date()
          const length = this.dateInput.length > 1 ? 2 : 1
          const month = today.getMonth() + 1
          let dateString = `${today.getFullYear()}-${month < 10 ? `0${month}` : month}-`
          let day = ''

          for (let counter = length; counter > 0; counter--) {
            day += this.dateInput[this.dateInput.length - counter]
          }

          if (day.toString().length < 2) {
            day = `0${day}`
          }

          if (Date.parse(`${dateString}${day}`)) {
            dateString += day
          } else {
            dateString += `0${this.dateInput[this.dateInput.length - 1]}`
          }

          this.date = dateString
          this.DateInputReset()

          let next = false
          let child = this
          let parent = this.$parent
          let found = false

          while (parent.$children[parent.$children.length - 1] === child) {
            if (next) {
              child = parent
              parent = parent.$parent
            }

            parent.$parent.$children.forEach(parentChild => {
              if (next) {
                child = parent
                parent = parentChild
                next = false
              } else {
                next = parentChild === parent
              }
            })
          }

          parent.$children.forEach(child => {
            if (!found && (parent !== this.$parent || next)) {
              let childElement = child

              while (childElement.$children.length > 0 && !childElement.focus) {
                childElement = childElement.$children[0]
              }

              childElement.focus()
              next = false
              found = true
            } else {
              next = child === this
            }
          })
        }
      }
    },
    DateInputReset () {
      this.dateInput = []
    },
    Emit () {
      if ((this.date && this.date.trim() !== '')) {
        this.$emit('input', `${this.date}T00:00:00`)
      } else {
        this.$emit('input', null)
      }
    },
    FocusWrong () {
      this.focusWrong = true
      this.focus = true

      this.$nextTick(() => {
        this.$refs.control.focus()
        this.focusWrong = false
      })
    },
    FormatData () {
      if (this.value) {
        this.date = moment(this.value, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD')
      } else {
        this.date = null
      }
    },
    MouseLeave () {
      this.hovering = false
      this.$emit('mouseleave')
    },
    MouseOver () {
      this.hovering = true
      this.$emit('mouseover')
    }
  }
}
</script>
