<template>
  <v-main
    class="ma-3"
  >
    <router-view />
  </v-main>
</template>

<script>
export default {
  name: 'TheView'
}
</script>
