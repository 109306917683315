<template>
  <draaiboek/>
</template>

<script>
import Draaiboek from '../components/Draaiboek.vue'

export default {
  name: 'DraaiboekView',
  components: {
    Draaiboek
  }
}
</script>
