<template>
  <v-container
    class="content pa-0"
    fluid
  >
    <v-row
      class="alternatingContentRow"
    >
    </v-row>
    <v-row
      class="alternatingContentRow"
    >
      <v-col
        v-if="!AuthenticationMixin_LoggedIn"
        class="center my-8 py-8"
        cols="12"
        lg="4"
      >
        <h1
          class="text-center"
        >
          Vul onderstaande gegevens in om gebruik te maken van uw persoonlijke account.
        </h1>
        <v-row
          class="text-center"
          justify="center"
        >
          <v-col
            class="center"
            cols="12"
            lg="8"
          >
            <v-text-field
              v-model="account.login"
              :autofocus="!account.login"
              base-color="primary"
              bg-color="white"
              color="primary"
              label="Gebruikersnaam"
              variant="outlined"
              ref="login"
              @keypress.enter="Login()"
            />
            <v-text-field
              v-model="account.wachtwoord"
              :autofocus="account.login && true"
              base-color="primary"
              bg-color="white"
              color="primary"
              label="Wachtwoord"
              variant="outlined"
              ref="wachtwoord"
              :type="showPassword ? 'text' : 'password'"
              @click:append-inner-icon="showPassword = !showPassword"
              @keypress.enter="Login()"
            >
            <template #append-inner>
              <v-icon
                @click="showPassword = !showPassword"
              >
                {{showPassword ? 'mdi-eye-off' : 'mdi-eye'}}
              </v-icon>
            </template>
            </v-text-field>
            <div
              class="centeredFlexbox"
            >
              <v-checkbox
                v-model="remember"
                base-color="primary"
                bg-color="white"
                color="primary"
                label="Onthoud mij"
              />
            </div>
            <v-btn
              class="mt-3"
              color="primary"
              tile
              @click="Login()"
            >
              Inloggen
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authenticationMixin from '@/mixins/authenticationMixin'
import commonMixin from '@/mixins/commonMixin'
import errorMixin from '@/mixins/errorMixin'

export default {
  name: 'LoginComponent',
  mixins: [
    authenticationMixin,
    commonMixin,
    errorMixin
  ],
  data () {
    return {
      account: {
        login: null,
        wachtwoord: null
      },
      remember: true,
      showPassword: false
    }
  },
  mounted () {
    if (this.AuthenticationMixin_LoggedIn) {
      this.$router.push('Account')
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.account.login = this.$cookie.get(this.authenticationMixin.cookie.name)
    }
  },
  methods: {
    Login () {
      this.AuthenticationMixin_Login(this.account, this.remember)
    }
  }
}
</script>
