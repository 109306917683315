<template>
  <v-dialog
    v-model="$store.state.blobDialog.show"
    class="blobDialog"
  >
    <vue-pdf-embed
      :source="$store.state.blobDialog.source"
    />
  </v-dialog>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue3-pdf-embed'

export default {
  name: 'TheBlobDialog',
  components: {
    VuePdfEmbed
  }
}
</script>

<style>
.blobDialog {
  max-height: 100vh !important;
  max-width: 100vw !important;
  min-height: 75vh !important;
  min-width: 75vw !important;
}

.blobDialog .vue-pdf-embed {
  overflow: scroll;
}
</style>
